import ReactEcharts from 'echarts-for-react'
import * as echarts from 'echarts'
import {
  CHART_GREEN_BG_LINEAR_GRADIENT,
  GREEN_LINE,
  WHITE_ALPHA_20,
  WHITE_ALPHA_60,
} from '../../constants/colors'
import { DINGTALK_JINBUTI } from '../../constants/font'
import useDataProvider from '../hooks/use-data-provider'
import React, {
  useEffect,
  useState,
} from 'react'
import { EChartsOption } from 'echarts-for-react/src/types'
import { clone } from 'ramda'

let option: EChartsOption = {
  title: {
    show: false,
  },
  toolbox: false,
  xAxis: {
    type: 'time',
    axisLabel: {
      formatter: (value: any) => {
        const date = new Date(value)
        return date.getFullYear().toString()
      },
    },
  },
  yAxis: {
    name: '元',
    type: 'value',
    splitLine: {
      lineStyle: {
        type: 'dashed',
        color: WHITE_ALPHA_20,
      },
    },
  },
  textStyle: {
    fontFamily: DINGTALK_JINBUTI,
    fontStyle: 'italic',
    fontWeight: 400,
    color: WHITE_ALPHA_60,
  },
  dataZoom: [
    {
      start: 0,
      end: 30,
      borderColor: 'transparent',
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
  ],
  series: [
    {
      name: '价格走势',
      type: 'line',
      symbol: 'circle',
      symbolSize: 6,
      color: GREEN_LINE,
      areaStyle: {
        color: CHART_GREEN_BG_LINEAR_GRADIENT,
      },
    },
  ],
}

export default function ProductPriceTrendingOverview() {

  const [chartOption, setChartOption] = useState(option)
  const { data, title, loading } = useDataProvider({
    transform(data) {
      let base = new Date().getFullYear()
      let mockData = [[new Date(base, 0, 1).getTime(), Math.random() * 100]]

      for (let i = 1; i < 30; i++) {
        base--
        let nextYear = new Date(base, 0, 1).getTime()
        mockData.push([nextYear, Math.random() * 20])
      }

      return mockData.reverse() // Reverse to show the data in ascending order of years
    },
  })

  console.log(data)

  useEffect(() => {
    let opt = clone(option)
    opt.series[0].data = data
    setChartOption(opt)
  }, [data])

  return (
    <div>
      <span style={{ color: '#fff' }}>
        商品价格走势
      </span>
      <ReactEcharts
        // showLoading={loading}
        style={{
          width: 400,
          height: 260,
        }}
        option={chartOption}
        echarts={echarts} />
    </div>
  )
}
