import { EChartsOption } from 'echarts-for-react/src/types'
import { DINGTALK_JINBUTI } from './font'
import { WHITE_ALPHA_60 } from './colors'

export const DEFAULT_PIE_CHART_OPTIONS: EChartsOption = {
  title: {
    show: true,
    left: 'center',
    top: 'center',
    textStyle: {
      fontSize: 20,
      lineHeight: 24,
      color: 'white',
    },
  },
  textStyle: {
    fontFamily: DINGTALK_JINBUTI,
    fontStyle: 'italic',
    fontWeight: 400,
  },
  series: [{
    type: 'pie',
    radius: [36, 44],
    label: {
      rich: {
        name: {
          fontSize: 12,
          color: WHITE_ALPHA_60,
          lineHeight: 14.4,
        },
        value: {
          fontSize: 16,
          color: '#fff',
          lineHeight: 16,
        },
        unit: {
          fontSize: 12,
          color: WHITE_ALPHA_60,
          lineHeight: 14.4,
        },
      },
    },
    labelLayout: function () {
      return {
        hideOverlap: true,
        moveOverlap: 'shiftY'
      };
    },
    labelLine: {
      length: 10,
      length2: 10,
      maxSurfaceAngle: 80,
      lineStyle: {
        color: WHITE_ALPHA_60,
      },
    },
  }],
}

