import classNames from "classnames";
import s from "./GradientText.module.css";

interface GradientTextProps extends React.HTMLAttributes<HTMLSpanElement> {
  gradient?: string;
  children: React.ReactNode
}

export default function GradientText({ className = '', gradient, children }: GradientTextProps) {
  return (
    <span
      className={classNames('teagarden-text-gradient', s.gradientText, className)}
      style={{ background: gradient }}
    >
      {children}
    </span>
  )
}