import {
  CHART_GREEN_LINEAR_GRADIENT,
  CHART_RED_LINEAR_GRADIENT,
  CHART_SILVER_LINEAR_GRADIENT,
  CHART_YELLOW_LINEAR_GRADIENT,
  GRAY_1,
  GREEN_LIGHT,
  RED_LIGHT,
  YELLOW_LIGHT,
} from '../../constants/colors'
import PieChartOverview from './PieChartOverview'

export default function IncomeOverview() {
  return (
    <div style={{marginTop: -24}}>
      <PieChartOverview
        optionsUpdate={(options) => {
          options.series[0].label.formatter = '{name|{b}} {value|{c}} {unit|%}'
          options.title.textStyle.fontSize = 14
          return options
        }}
        dataProvider={{
          api: "/income/list",
          transform: (d: DataResponse<DataRecords<IncomeOverview>>) => {
            return d.result?.records?.map((rec) => {
              if (!rec) return {};
      
              let itemStyle;
              switch (rec.incomeType) {
                case '种植收入':
                  itemStyle = { color: CHART_GREEN_LINEAR_GRADIENT, borderColor: GREEN_LIGHT }
                  break;
                case '养殖收入':
                  itemStyle = { color: CHART_SILVER_LINEAR_GRADIENT, borderColor: GRAY_1 }
                  break;
                case '第三产收入':
                  itemStyle = { color: CHART_YELLOW_LINEAR_GRADIENT, borderColor: YELLOW_LIGHT }
                  break;
                default:
                  itemStyle = { color: CHART_RED_LINEAR_GRADIENT, borderColor: RED_LIGHT }
                  break;
              }
              return {
                name: rec.incomeType,
                value: rec.proOfIncome,
                itemStyle,
              }
            })
          },
          getTitle: () => '收入占比',
        }} />
    </div>
  )
}
