import { useState } from 'react'
import TitleWithIcon from './components/TitleWithIcon'
import Intro from './components/Intro'
import BG from '../assets/images/traceability/bg.png'
import Layout from './components/Layout'
import ProductTrendingOverview from './components/ProductTrendingOverview'
import ProductTrendingLineOverview from './components/ProductTrendingLineOverview'
import RiceConsumptionOverview from './components/RiceConsumptionOverview'
import IncomeOverview from './components/IncomeOverview'
import SubTitleWithIcon from './components/SubTitleWithIcon'
import AgriculturalProductTraceability from './components/AgriculturalProductTraceability'
import OutputOfBreedingProducts from './components/OutputOfBreedingProducts'
import CommodityPriceTrend from './components/CommodityPriceTrend'
import FieldProductionProcessModal from './components/modals/FieldProductionProcessModal'
import useWindowZoom from './hooks/use-window-zoom'
import Dropdown from './components/Dropdown'
import useDataProvider from './hooks/use-data-provider';

function ModalButton({ label, activeTab }: { label: string, activeTab?: 'tab-1' | 'tab-2' }) {
  const [active, setActive] = useState(false)

  return (
    <FieldProductionProcessModal
      active={activeTab}
      trigger={
        <div>
          <Dropdown label={label} active={active} />
        </div>
      }
      handleClose={() => {
        setActive(false)
      }}
      handleOpen={() => {
        setActive(true)
      }}
    />
  )
}

export default function AgricultureJourney() {
  const { data: enterpriseIntroduceItems } = useDataProvider({
    api: '/introduction/list',
    transform: (d: DataResponse<EnterpriseIntroduceRecords>) => {
      return d.result.records.map((rec) => {
        return {
          title: rec.subheading,
          textContent: rec.content
        };
      })
    },
  });

  const zoom = useWindowZoom()

  return (
    <Layout
      bg={BG}
      left={
        <>
          <div className="teagarden-layout-item">
            <TitleWithIcon title="企业介绍" />
            <Intro title={enterpriseIntroduceItems?.[0]?.title}
              textContent={enterpriseIntroduceItems?.[0]?.textContent} />
          </div>
          <div className="teagarden-layout-item">
            <TitleWithIcon title="出库商品占比及趋势" />
            <ProductTrendingOverview />
            <div className="teagarden-layout-subitem" style={{ marginTop: 24 }}>
              <ProductTrendingLineOverview />
            </div>
          </div>
          <div className="teagarden-layout-item">
            <TitleWithIcon title="贡米消费特征分析" />
            <RiceConsumptionOverview />
          </div>
        </>
      }
      right={
        <>
          <div className="teagarden-layout-item" style={{ marginBottom: 36 }}>
            <TitleWithIcon title="收入占比" dir="right" />
            <div style={{ marginTop: 36 }}><IncomeOverview /></div>
          </div>
          <div className="teagarden-layout-item">
            <TitleWithIcon title="本年种养殖产品产量" dir="right" />
            <OutputOfBreedingProducts />
            <div className="teagarden-layout-subitem">
              <SubTitleWithIcon style={{ marginTop: 16 }} title="农产品溯源" dir="right" />
              <AgriculturalProductTraceability />
            </div>
          </div>
          <div className="teagarden-layout-item">
            <TitleWithIcon title="商品价格走势" dir="right" />
            <div className="teagarden-layout-subitem" style={{ marginTop: 24 }}>
              <CommodityPriceTrend />
            </div>
          </div>
        </>
      }
    >
      <div className="teagarden-button-group" style={{ zoom, width: 168 }}>
        <ModalButton label="贡米生长及加工流程" />
        <ModalButton label="贡茶生长及加工流程" activeTab='tab-2' />
      </div>
    </Layout>
  )
}
