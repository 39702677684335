import HorizontalDataTable from './HorizontalDataTable'
import TouristGenderOverview from './TouristGenderOverview'
import React from 'react'

export default function TouristOverview() {
  return (
    <div style={{ display: 'flex', gap: 16, flexDirection: 'column' }}>
      <TouristGenderOverview />
      <HorizontalDataTable
        titleWidth={85}
        unit={'人'}
        data={[
          { name: '一线城市', value: 1504 },
          { name: '新一线城市', value: 1848 },
          { name: '二线城市', value: 1292 },
          { name: '三线城市', value: 785 },
          { name: '四线及其他', value: 526 },
        ]} />
    </div>
  )
}
