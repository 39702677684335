export const GREEN_1 = '#069E7D'
export const GREEN_2 = '#04D5A8'
export const GREEN_LIGHT = '#02F5C1'

export const GREEN_LINE = '#36F097'

export const YELLOW_1 = '#9E6A06'
export const YELLOW_2 = '#D5AF04'
export const YELLOW_LIGHT = '#F5C802'

export const WHITE_ALPHA_60 = 'rgba(255, 255, 255, 0.6)'
export const WHITE_ALPHA_20 = 'rgba(255, 255, 255, 0.2)'

export const GRAY_1 = 'rgb(170, 200, 212)'
export const RED_LIGHT = 'rgb(240, 180, 155)'

export const CHART_GREEN_LINEAR_GRADIENT = {
  type: 'linear',
  x: 0,
  y: 0,
  x2: 0,
  y2: 1,
  colorStops: [{
    offset: 0, color: GREEN_1, // 0% 处的颜色
  }, {
    offset: 1, color: GREEN_2, // 100% 处的颜色
  }],
  global: false, // 缺省为 false
}

export const CHART_YELLOW_LINEAR_GRADIENT = {
  type: 'linear',
  x: 0,
  y: 0,
  x2: 0,
  y2: 1,
  colorStops: [{
    offset: 0, color: YELLOW_1, // 0% 处的颜色
  }, {
    offset: 1, color: YELLOW_2, // 100% 处的颜色
  }],
  global: false, // 缺省为 false
}

export const CHART_SILVER_LINEAR_GRADIENT = {
  type: 'linear', x: 0, y: 0, x2: 0, y2: 1,
  colorStops: [{
    offset: 0, color: '#3C5D6A', // 0% 处的颜色
  }, {
    offset: 1, color: 'rgba(98, 126, 139, 1)', // 100% 处的颜色
  }],
  global: false, // 缺省为 false
}

export const CHART_RED_LINEAR_GRADIENT = {
  type: 'linear', x: 0, y: 0, x2: 0, y2: 1,
  colorStops: [{
    offset: 0, color: 'rgba(229, 128, 84, 1)', // 0% 处的颜色
  }, {
    offset: 1, color: 'rgba(136, 77, 50, 1)', // 100% 处的颜色
  }],
  global: false, // 缺省为 false
}

export const CHART_GREEN_BG_LINEAR_GRADIENT = {
  type: 'linear',
  x: 0,
  y: 0,
  x2: 0,
  y2: 1,
  colorStops: [{
    offset: 0, color: 'rgba(4, 213, 168, .5)', // 0% 处的颜色
  }, {
    offset: 1, color: 'rgba(4, 213, 168, .05)', // 100% 处的颜色
  }],
  global: false, // 缺省为 false
}

export const CHART_LIGHT_BLUE_LINEAR_GRADIENT = {
  type: 'linear', x: 0, y: 0, x2: 0, y2: 1,
  colorStops: [{
    offset: 0, color: 'rgba(185, 210, 220, 1)', // 0% 处的颜色
  }, {
    offset: 1, color: 'rgba(185, 210, 220, 0.16)', // 100% 处的颜色
  }],
  global: false, // 缺省为 false
}

export const CHART_LIGHT_GREEN_LINEAR_GRADIENT = {
  type: 'linear', x: 0, y: 0, x2: 0, y2: 1,
  colorStops: [{
    offset: 0, color: 'rgba(5, 202, 159, 1)', // 0% 处的颜色
  }, {
    offset: 1, color: 'rgba(5, 202, 159, 0.16)', // 100% 处的颜色
  }],
  global: false, // 缺省为 false
}

export const CHART_LIGHT_YELLOW_LINEAR_GRADIENT = {
  type: 'linear', x: 0, y: 0, x2: 0, y2: 1,
  colorStops: [{
    offset: 0, color: 'rgba(246, 202, 62, 1)', // 0% 处的颜色
  }, {
    offset: 1, color: 'rgba(246, 202, 62, 0.16)', // 100% 处的颜色
  }],
  global: false, // 缺省为 false
}

export const CHART_LIGHT_RED_LINEAR_GRADIENT = {
  type: 'linear', x: 0, y: 0, x2: 0, y2: 1,
  colorStops: [{
    offset: 0, color: 'rgba(244, 152, 111, 1)', // 0% 处的颜色
  }, {
    offset: 1, color: 'rgba(244, 152, 111, 0.16)', // 100% 处的颜色
  }],
  global: false, // 缺省为 false
}
