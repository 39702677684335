import classNames from 'classnames';
import s from './CornerDecorationImg.module.css';

interface CornerDecorationImgProps extends React.ImgHTMLAttributes<HTMLImageElement>{
  src: string;
  width: number;
  height: number;
  style?: React.CSSProperties;
}

export default function CornerDecorationImg({ src, width, height, style }: CornerDecorationImgProps) {
  return (
    <div 
      className={s.cornerDecorationImg} 
      style={{ width, height, ...style }}
      >
      <div className={classNames(s.decoration, s.decorationTopLeft)} />
      <div className={classNames(s.decoration, s.decorationTopRight)} />
      <div className={classNames(s.decoration, s.decorationBottomLeft)} />
      <div className={classNames(s.decoration, s.decorationBottomRight)} />
      <img src={src} alt="" />
    </div>
  )
}