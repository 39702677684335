import {
  useCallback,
  useState,
} from 'react'
import { useAsync } from 'react-use'
import { identity } from 'ramda'

export interface ChartDataItem {
  value: number
  name: string | number
  itemStyle?: any

  [key: string]: any
}

export interface UseDataProviderProps<T> {
  api?: string
  transform?: TransformFunction<T>
  getTitle?: (data: any) => string
}

export type TransformFunction<T> = (data: any) => T

const apiEndpoint = window.APP_CONFIG.REACT_APP_API_ENDPOINT

export default function useDataProvider<T = any>({
  api, transform, getTitle,
}: UseDataProviderProps<T>) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<T>()
  const [title, setTitle] = useState('')

  const fetchData = useCallback(async () => {
    setLoading(true)
    let data
    if (api) {
      try {
        const isAbsoluteUrl = api.startsWith('http')
        const response = await fetch(isAbsoluteUrl ? api : `${apiEndpoint}${api}`)
        data = await response.json()
      } catch (e) {
        console.log(e)
      }
    }

    try {
      if (transform) {
        data = transform(data)
      }
      setData(data)
      setTitle(getTitle?.(data) || '')
    } catch (e) {
    }

    setLoading(false)
  }, [api])

  useAsync(async () => {
    await fetchData()
  }, [])

  return { loading, data, title, fetchData }
}
