import s from './ProgressBar.module.css'

export interface ProgressBarProps {
  ratio: number
}

export default function ProgressBar(
  { ratio }: ProgressBarProps,
) {
  return (
    <div className={s.progressBar}>
      <div className={s.progress} style={{ width: `${ratio * 95}%` }}></div>
    </div>
  )
}
