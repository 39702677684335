import s from './Notice.module.css';
import Icon from '../../assets/images/notice/icon.png';
import useWindowZoom from '../hooks/use-window-zoom';
import useDataProvider from '../hooks/use-data-provider';

export default function Notice() {
  const zoom = useWindowZoom()

  const { data } = useDataProvider({
    api: '/announcement/today-announcement',
    transform: (d: DataResponse<News>) => {
      return {
        title: d.result.title,
        content: d.result.content
      };
    },
  });

  return data?.content ? (
    <div className={s.notice} style={{ zoom }}>
      <img src={Icon} className={s.icon} />
      <div>
        <h1 className={s.title}>{data?.title}</h1>
        <p className={s.content}>{data?.content}</p>
      </div>
    </div>
  ) : null
}