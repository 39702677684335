import {
  ReactNode,
  useState,
} from 'react'
import ModalWithTrigger, { ModalWithTriggerProps } from './ModalWithTrigger'

import s from './ContentModal.module.css'
import modalStyle from './Modal.module.css'
import ImageButton from '../ImageButton'

export interface ContentModalProps extends ModalWithTriggerProps {
  content?: ReactNode
}

export default function ContentModal(
  { trigger, content, ...rest }: ContentModalProps,
) {
  return (
    <ModalWithTrigger
      className={s.contentModal}
      trigger={trigger}
      {...rest}
    >
      {(toggle) => (
        <>
          <div className={modalStyle.closeButton}>
            <ImageButton active name={'close'} onClick={() => toggle(false)} />
          </div>
          <div className={s.contentWrapper}>
            {content}
          </div>
        </>
      )}
    </ModalWithTrigger>
  )
}
