import classNames from 'classnames'
import s from './NavItem.module.css'
import { useLocation } from 'react-use'
import BubbleAnimation from './BubbleAnimation'

interface INavItem {
  active?: boolean
  animation?: boolean
  dir?: 'column' | 'row';
  src: string;
  label: string;
  styles?: React.CSSProperties;
  onClick?: () => void;
}

export default function NavItem({
  active,
  animation = true,
  src,
  dir = 'row',
  label,
  onClick,
  styles,
}: INavItem) {

  return (
    <div
      className={classNames(s.navItem, {
        [s.column]: dir === 'column',
        [s.active]: active,
      })}
      style={{ flexDirection: dir, ...styles }}
      onClick={onClick}
    >
      {active && animation && <div>
        <BubbleAnimation count={30} color={'#b8fff0'} />
      </div>}
      <img src={src} className={s.icon} alt="" />
      <span className={s.label}>{label}</span>
    </div>
  )
}
