import React from 'react'
import {
  CHART_GREEN_LINEAR_GRADIENT,
  CHART_YELLOW_LINEAR_GRADIENT,
  GREEN_LIGHT,
  YELLOW_LIGHT,
} from '../../constants/colors'
import PieChartOverview from './PieChartOverview'
import { ChartDataItem } from '../hooks/use-data-provider'

const WeeklyFeedOverview = () => {
  return (
    <div>
      <PieChartOverview
        optionsUpdate={(options) => {
          options.series[0].label.formatter = '{name|{b}}\n{value|{c}} {unit|尾}'
          return options
        }}
        dataProvider={{
          api: `/batrachish/list`,
          transform(d: DataResponse<BatrachishRecords>) {
            const riceFrogCount = d.result.records?.reduce((acc, cur) => acc + cur.riceFrogCount, 0) || 0;
            const riceFishCount = d.result.records?.reduce((acc, cur) => acc + cur.riceFishCount, 0) || 0;
            const total_ = riceFrogCount + riceFishCount;

            const chartData: ChartDataItem[] = [
              {
                name: '稻鱼喂食数量',
                value: riceFishCount,
                itemStyle: {
                  color: CHART_GREEN_LINEAR_GRADIENT, borderColor: GREEN_LIGHT,
                },
              },
              {
                name: '稻蛙喂食数量',
                value: riceFrogCount,
                itemStyle: { color: CHART_YELLOW_LINEAR_GRADIENT, borderColor: YELLOW_LIGHT },
              },
            ]

            const total = [].reduce((acc: number, item: any) => acc + item.value, 0) || total_
            const gapValue = total * 0.01
            const gapItem = {
              value: gapValue,
              name: '',
              itemStyle: { color: 'transparent' },
              label: { show: false },
            }
            chartData.splice(1, 0, gapItem)
            chartData.splice(3, 0, gapItem)

            return chartData
          },
          getTitle: (data) => {
            const total = data.reduce((acc: number, item: ChartDataItem) => {
              if (item.name == '')
                return acc;

              return acc + item.value;
            }, 0) || 0;

            return `${total}\n总计`;
          },
        }} />
    </div>
  )
}

export default WeeklyFeedOverview
