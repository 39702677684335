import TitleWithIcon from './components/TitleWithIcon'
import Intro from './components/Intro'
import FarmDataOverview from './components/FarmDataOverview'
import Roster from './components/Roster'
import WeeklyFeedOverview from './components/WeeklyFeedOverview'
import SubTitleWithIcon from './components/SubTitleWithIcon'
import AquacultureSituation from './components/AquacultureSituation'
import WeatherForecast from './components/WeatherForecast'
import VideoMonitoring from './components/VideoMonitoring'
import BG from '../assets/images/intelligent-agriculture/bg.png'
import Layout from './components/Layout'
import IntroImg from '../assets/images/intro/img.png'
import Notice from './components/Notice'
import CheckboxList from './components/CheckboxList'
import Icon1 from '../assets/images/checkbox/field-icon.svg'
import Icon2 from '../assets/images/checkbox/tea-garden-icon.svg'
import Icon3 from '../assets/images/checkbox/frog-icon.svg'
import Icon4 from '../assets/images/checkbox/fish-icon.svg'
import Dropdown from './components/Dropdown'
import Switch from './components/Switch'
import useWindowZoom from './hooks/use-window-zoom'
import useDataProvider from './hooks/use-data-provider'

const ASSET_PATH = window.APP_CONFIG.REACT_APP_ASSET_PREFIX_PATH

export default function IntelligentAgriculture() {
  const { data: introItems, loading } = useDataProvider({
    api: '/agriculture/list',
    transform: (d: DataResponse<AgricultureRecords>) => {
      return d.result.records.map((rec) => {
        const imgs = rec.picture
          ?.split(',')
          .map((path) => `${ASSET_PATH}/${path}`)
        return {
          name: rec.title,
          intro: rec.content,
          images: imgs,
        }
      })
    },
  })

  const zoom = useWindowZoom()
  return (
    <Layout
      bg={BG}
      left={
        <>
          <div className='teagarden-layout-item'>
            <TitleWithIcon title='智慧农业' />
            <Intro tabContent={introItems} />
          </div>
          <div className='teagarden-layout-item'>
            <TitleWithIcon title='农户统计' />
            <FarmDataOverview />
          </div>
          <div className='teagarden-layout-item'>
            <TitleWithIcon title='田地流转花名册' />
            <Roster />
          </div>
        </>
      }
      right={
        <>
          <div className='teagarden-layout-item'>
            <TitleWithIcon title='本周蛙鱼饲料投喂统计' dir='right' />
            <div style={{ marginBottom: 8 }}>
              <WeeklyFeedOverview />
            </div>
            <div
              className='teagarden-layout-subitem'
              style={{ marginTop: 32 * zoom }}
            >
              <SubTitleWithIcon dir='right' title='蛙鱼养殖情况概览' />
              <AquacultureSituation />
            </div>
          </div>
          <div className='teagarden-layout-item'>
            <TitleWithIcon title='农气预警' dir='right' />
            <WeatherForecast />
          </div>
          <div className='teagarden-layout-item'>
            <TitleWithIcon title='视频监控' dir='right' />
            <VideoMonitoring />
          </div>
        </>
      }
    >
      <Notice />
      <CheckboxList
        data={[
          { icon: Icon1, label: '稻田' },
          { icon: Icon2, label: '茶园' },
          { icon: Icon3, label: '稻蛙' },
          { icon: Icon4, label: '稻鱼' },
        ]}
      />
      <div className='teagarden-button-group ' style={{ zoom }}>
        <Dropdown label='草莓采摘园' content={<Switch label='排风扇' />} />
        <Dropdown label='采摘大棚' content={<Switch label='遮阳棚' />} />
      </div>
    </Layout>
  )
}
