import s from './Roster.module.css'
import useDataProvider from '../hooks/use-data-provider';

export default function Roster() {
  const { data: dataSource } = useDataProvider({
    api: '/circulation/list',
    transform: (d: DataResponse<CirculationRecords>) => {
      return d.result.records.map((rec, idx) => {
        return {
          id: rec.id,
          order: `${idx + 1}`,
          address: rec.address,
          acreage: `${rec.paddyField}亩`,
        };
      })
    },
  });

  const flowData = [
    {
      key: 1,
      name: '转让',
      color: '#EABD2D'
    },
    {
      key: 2,
      name: '出租',
      color: '#2EE9E9'
    },
    {
      key: 3,
      name: '互换',
      color: '#49E578'
    }
  ]

  const columns = [
    {
      title: '编号',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: '地理位置',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '土地面积',
      dataIndex: 'acreage',
      key: 'acreage',
    },
    // {
    //   title: '流转类型',
    //   dataIndex: 'flowtype',
    //   key: 'flowtype',
    // },
  ]

  return (
    <table className={s.rosterTable}>
      <thead>
        <tr className={s.thItem}>
          {
            columns.map((c) => {
              return <th className={s.th} key={c.key}>{c.title}</th>
            })
          }
        </tr>
      </thead>
      <tbody className={s.tbody}>
        {
          dataSource?.map((d, i) => {
            // const flowValue = flowData.find((f) => f.key === d.flowtype)
            return (
              <tr key={d.id} className={s.tbItem}>
                <td className={s.td}>{d.order}</td>
                <td className={s.td}>{d.address}</td>
                <td className={s.td}>{d.acreage}</td>
                {/* <td className={s.td} style={{ color: flowValue?.color }}>{flowValue?.name}</td> */}
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}
