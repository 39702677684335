import s from './TouristGenderOverview.module.css'

export default function TouristGenderOverview() {
  return (
    <div className={s.touristGenderOverview}>
      <div className={s.genderInfo}>
        <span>男</span>
        <span>76%</span>
      </div>
      <div className={s.ratioInfo}>
        <span className={s.genderMale} style={{ width: '76%' }}></span>
        <span className={s.genderFemale} style={{ width: '24%' }}></span>
      </div>
      <div className={s.genderInfo}>
        <span>女</span>
        <span>24%</span>
      </div>
    </div>
  )
}
