import ReactEcharts from 'echarts-for-react'
import * as echarts from 'echarts'
import {
  CHART_GREEN_BG_LINEAR_GRADIENT,
  GREEN_LINE,
  WHITE_ALPHA_20,
  WHITE_ALPHA_60,
} from '../../constants/colors'
import { DINGTALK_JINBUTI } from '../../constants/font'
import useDataProvider from '../hooks/use-data-provider'
import {
  useEffect,
  useState,
} from 'react'
import { EChartsOption } from 'echarts-for-react/src/types'
import { clone } from 'ramda'

let option: EChartsOption = {
  title: {
    show: false,
  },
  toolbox: false,
  xAxis: {
    type: 'category',
    axisLabel: {
      formatter: (value: string) => {
        return `${value}月`;
      },
    },
  },
  yAxis: {
    name: '人',
    type: 'value',
    splitLine: {
      lineStyle: {
        type: 'dashed',
        color: WHITE_ALPHA_20,
      },
    },
  },
  textStyle: {
    fontFamily: DINGTALK_JINBUTI,
    fontStyle: 'italic',
    fontWeight: 400,
    color: WHITE_ALPHA_60,
  },
  dataZoom: [
    {
      start: 0,
      end: 30,
      borderColor: 'transparent',
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
  ],
  series: [
    {
      name: '销售额',
      type: 'line',
      symbol: 'circle',
      symbolSize: 6,
      color: GREEN_LINE,
      areaStyle: {
        color: CHART_GREEN_BG_LINEAR_GRADIENT,
      },
    },
  ],
}

export default function VisitorTrafficOverview() {

  const [chartOption, setChartOption] = useState(option)
  const { data, title, loading } = useDataProvider({
    api: '/flowanalysis/smartPassengerFlowAnalysis/get-line-chart',
    transform(data: DataResponse<VisitorTraffic[]>) {
      return data.result?.map((item) => {
        return [item.month, item.peopleCount];
      })
    },
  })

  useEffect(() => {
    let opt = clone(option)
    opt.series[0].data = data
    setChartOption(opt)
  }, [data])

  return (
    <div style={{marginTop: -16}}>
      <ReactEcharts
        // showLoading={loading}
        style={{
          width: 400,
          height: 260,
        }}
        option={chartOption}
        echarts={echarts} />
    </div>
  )
}
