import { useMemo } from 'react';
import HotPlate from './HotPlate';
import Img from '../../assets/images/hot-plate/img-1.png';
import useDataProvider from '../hooks/use-data-provider';
import classNames from 'classnames';
import s from './HotTravelPlace.module.css';

export default function HotTravelPlace() {
  const { data: popularTravelItems } = useDataProvider({
    api: '/populartravel/smartTourismPopularTravel/get-all',
    transform: (d: DataResponse<PopularTravelling[]>) => {
      return d.result.map((rec) => {
        return {
          height: 80,
          title: rec.scenicSpotName,
          coverUrl: Img, //rec.scenicAreaPictures, // TODO: 图片地址待处理
          content: [`最佳旅游时间：${rec.bestTravelTime}`, `开放时间：${rec.openingHours}`],
          pay: rec.ticketPurchaseNotice,
          detail: rec
        };
      })
    },
  });

  const places = useMemo(() => {
    return popularTravelItems;
  }, [popularTravelItems]) || [];

  return (
    <div className={classNames('teagarden-overflow-scroll', s.hotTravelPlace)}>
      {
        places.map((p, idx) => {
          return (
            <HotPlate
              key={`hot-travel-place-${idx}`}
              type={'景点'}
              {...p}
            />
          )
        })
      }
    </div>
  )
}