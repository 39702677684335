import { useState } from 'react'
import ModalWithTrigger, { ModalWithTriggerProps } from './ModalWithTrigger'

import modalStyle from './Modal.module.css'
import s from './FieldProductionProcessModal.module.css'
import ImageButton from '../ImageButton'
import GongMiContent from './GongMiContent'
import GongTeaContent from './GongTeaContent'
import SvgIcon from '../svg-icon/SvgIcon'

interface Props extends ModalWithTriggerProps {
  active?: 'tab-1' | 'tab-2';
}

export default function FieldProductionProcessModal(
  { trigger, active = 'tab-1', ...rest }: Props,
) {
  const [activeTab, setActiveTab] = useState(active)
  return (
    <ModalWithTrigger trigger={trigger} isOverlay {...rest}>
      {(toggle) => (
        <>
          <div className={modalStyle.closeButton}>
            <ImageButton active name={'close'} onClick={() => toggle(false)} />
          </div>
          <div className={s.tabButtons}>
            <ImageButton
              name={'tab-1'}
              text="贡米"
              icon={<SvgIcon name="gongmi" size={20} />}
              active={activeTab === 'tab-1'}
              onClick={() => setActiveTab('tab-1')}
            />
            <ImageButton
              name={'tab-2'}
              text="贡茶"
              icon={<SvgIcon name="gongcha" size={20} />}
              active={activeTab === 'tab-2'}
              onClick={() => setActiveTab('tab-2')}
            />
          </div>
          {activeTab === 'tab-1' && <GongMiContent />}
          {activeTab === 'tab-2' && <GongTeaContent />}
        </>
      )}
    </ModalWithTrigger>
  )
}
