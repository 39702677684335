import ReactEcharts from 'echarts-for-react'
import * as echarts from 'echarts'
import {
  CHART_GREEN_BG_LINEAR_GRADIENT,
  GREEN_LINE,
  WHITE_ALPHA_20,
  WHITE_ALPHA_60,
} from '../../constants/colors'
import { DINGTALK_JINBUTI } from '../../constants/font'
import useDataProvider from '../hooks/use-data-provider'
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { EChartsOption } from 'echarts-for-react/src/types'
import { clone } from 'ramda'
import Tab from './Tab'

let option: EChartsOption = {
  title: {
    show: false,
  },
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  toolbox: false,
  legend: false,
  grid: {   // Ensures no extra white space
    top: 16,
    bottom: 16,
    left: 16,
    right: 16,
    containLabel: true,  // Makes sure the labels are within the chart
  },
  xAxis: {
    type: 'time',
    axisLabel: {
      formatter: (value: any) => {
        const date = new Date(value)
        return date.getFullYear().toString()
      },
    },
  },
  yAxis: {
    type: 'value',
    splitLine: {
      lineStyle: {
        type: 'dashed',
        color: WHITE_ALPHA_20,
      },
    },
  },
  textStyle: {
    fontFamily: DINGTALK_JINBUTI,
    fontStyle: 'italic',
    fontWeight: 400,
    color: WHITE_ALPHA_60,
  },
  series: [
    {
      name: '销售额',
      type: 'line',
      symbol: 'circle',
      symbolSize: 6,
      color: GREEN_LINE,
      areaStyle: {
        color: CHART_GREEN_BG_LINEAR_GRADIENT,
      },
    },
  ],
}

export default function ProductTrendingLineOverview() {
  const [typeName, setTypeName] = useState('贡米');

  const [chartOption, setChartOption] = useState(option)
  const { data, fetchData } = useDataProvider({
    api: `/product/proportion?name=${typeName}`,
    transform(data: DataResponse<ProductingTrend[]>) {
      let minYear: number | null = null;
      let maxYear: number | null = null;
      const years: number[] = [];
      data.result.forEach((item) => { // 计算开始、结束年份
        if (minYear == null) minYear = item.name;
        else if (item.name < minYear) minYear = item.name;
        if (maxYear == null) maxYear = item.name;
        else if (item.name > maxYear) maxYear = item.name;
        years.push(item.name);
      });
      if (minYear != null && maxYear != null) { // 确保数据年份连续
        for (let yr = minYear; yr <= maxYear; ++yr) {
          if (!years.includes(yr)) {
            data.result.push(
              {
                name: yr,
                value: 0,
              }
            );
          }
        }
      }

      return data.result.map((item) => {
        return [new Date(item.name, 0, 1).getTime(), item.value];
      }).sort((item1, item2) => item1[0] - item2[0]);
    },
  });

  useEffect(() => {
    let opt = clone(option)
    opt.series[0].data = data
    setChartOption(opt)
  }, [data])

  useEffect(() => {
    fetchData();
  }, [typeName]);

  const content = useMemo(() => {
    return <ReactEcharts style={{ width: 360, height: 140 }} option={chartOption} echarts={echarts} />
  }, [chartOption, echarts])

  const tabItems = ["贡米", "贡茶", "稻蛙", "稻鱼"].map((item) => ({ name: item, content }));

  return (
    <Tab
      items={tabItems}
      onChange={(name) => setTypeName(name)}
    />
  )
}
