import s from './GongTeaContent.module.css'
import ImageButton from '../ImageButton'
import { useState } from 'react'
import classNames from 'classnames'

const Content = {
  Content1: 'button-1',
  Content2: 'button-2',
  Content3: 'button-3',
}

export default function GongTeaContent() {

  const [activeContent, setActiveContent] = useState(Content.Content1)

  return (<div className={s.GongMiContent}>
    <div className={s.stepButtons}>
      <ImageButton
        name={Content.Content1}
        active={activeContent === Content.Content1}
        onClick={() => setActiveContent(Content.Content1)}
        text="田间生产过程"
      />
      <ImageButton
        name={Content.Content2}
        active={activeContent === Content.Content2}
        onClick={() => setActiveContent(Content.Content2)}
        text="加工过程"
      />
    </div>
    <div>
      {activeContent === Content.Content1 && <Content1 />}
      {activeContent === Content.Content2 && <Content2 />}
    </div>
  </div>
  )
}

function Content1() {
  return (
    <div className={s.fieldProductionProcess}>
      <div className={s.u1}></div>
      {Array.from({ length: 7 }).map((_, i) => (
        <span key={i}
          className={classNames(s.gongTeaContentStep, s[`gongTeaContent1Step${i + 1}`])}
          style={{ animationDelay: `${i * 800}ms` }}
        ></span>
      ))}
    </div>
  )
}

function Content2() {
  return (
    <div className={s.productionProcess}>
      <div className={s.u1}></div>
      {Array.from({ length: 7 }).map((_, i) => (
        <span key={i}
          className={classNames(s.gongTeaContentStep, s[`gongTeaContent2Step${i + 1}`])}
          style={{ animationDelay: `${i * 800}ms` }}
        ></span>
      ))}
    </div>
  )
}
