import { useMemo } from 'react';
import GradientButton from './GradientButton';
import SubTitleWithIcon from './SubTitleWithIcon';
import CaptureImg from '../../assets/images/video-monitoring/capture-img.png';
import Placement1 from '../../assets/images/video-monitoring/placement-1.png';
import Placement2 from '../../assets/images/video-monitoring/placement-2.png';
import CornerDecorationImg from './CornerDecorationImg';
import s from './VideoMonitoring.module.css';
import Icon from '../../assets/images/video-monitoring/placement-icon.svg';
import Dropdown from './Dropdown';
import Switch from './Switch';

export default function VideoMonitoring() {
  const capture = useMemo(() => {
    return (
      <div className={s.capture}>
        <CornerDecorationImg src={CaptureImg} width={136} height={80} style={{ marginRight: '16px' }} />
        <div className={s.content}>
          <h3 className='teagarden-text-gradient'>小动物检测</h3>
          <p className={s.contentItem}>
            <span className={s.label}>检测事件：</span>
            <span>检测到小动物</span>
          </p>
          <p className={s.contentItem}>
            <span className={s.label}>抓拍时间：</span>
            <span>2024.08.13  10:48</span>
          </p>
          <p className={s.contentItem}>
            <span className={s.label}>点位名称：</span>
            <span>竹溪县中峰镇贡米产业园蒸蒸日上旁</span>
          </p>
        </div>
      </div>
    )
  }, []);

  const monitoringContent = useMemo(() => {
    return (
      <div className={s.monitoringContent}>
        <div className={s.dropdown}>
          <Dropdown
            icon={Icon}
            label="一号大棚"
            type="gradient"
          />
        </div>
        <div className={s.placements}>
          <CornerDecorationImg src={Placement1} width={168} height={98} />
          <CornerDecorationImg src={Placement2} width={168} height={98} />
        </div>
      </div>
    )
  }, [])

  return (
    <div className={s.videoMonitoring}>
      <div className={s.title}>
        <SubTitleWithIcon title="预警抓拍" style={{ marginBottom: 0 }} />
        <GradientButton style={{ width: '44px', height: '22px' }}>详情</GradientButton>
      </div>
      {capture}
      {monitoringContent}
    </div>
  )
}