import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './Intro.module.css';
import classNames from 'classnames';
import ImgSwiper from './ImgSwiper';
import Tab from './Tab';

interface TabData {
  name: string;
  intro: string;
  images: string[];
}
interface IntroProps {
  tabContent?: TabData[];
  title?: string;
  textContent?: string;
}

interface ScrollTextProps {
  text: string;
  height: number
}

function ScrollText({ text, height }: ScrollTextProps) {
  const textWrapperRef = useRef<any>(null);
  const textRef = useRef<any>(null);
  const timer = useRef<any>(null);

  const clear = useCallback(() => {
    if (timer.current) clearInterval(timer.current);
  }, []);

  const scroll = useCallback(() => {
    if (!textRef.current) return;
    clear();

    timer.current = setInterval(() => {
      const srollTop = textWrapperRef.current.scrollTop;

      let nextTop = srollTop + 1;
      if (nextTop > textRef.current.clientHeight - textWrapperRef.current.clientHeight) {
        clear();
      } else {
        textWrapperRef.current.scrollTo({
          top: nextTop,
          behavior: 'smooth'
        })
      }
    }, 100)
  }, []);

  useEffect(() => {
    scroll();
    return () => { clear() }
  }, [scroll]);

  return (
    <div ref={textWrapperRef} className={s.textWrapper} style={{ height }}>
      <p ref={textRef} className={s.text} onMouseEnter={clear} onMouseLeave={scroll}>
        {text}
      </p>
    </div>
  )
}

export default function Intro({ tabContent, title, textContent }: IntroProps) {
  const TabRef = useRef<any>(null);

  const tab = useMemo(() => {
    if (!tabContent?.length) return null;
    const items = tabContent?.map((item, idx) => {
      return {
        ...item,
        content: (
          <div className={s.content} key={`intro-text-${idx}`}>
            <ScrollText text={item.intro} height={89} />
            <ImgSwiper key={`img-swiper-${idx}`} data={item.images} />
          </div>
        )
      }
    })
    return (
      <Tab ref={TabRef} items={items} />
    )
  }, [tabContent]);

  const text = useMemo(() => {
    if (!textContent) return null;
    return (
      <>
        <h2 className={classNames(s.title, 'teagarden-text-gradient')}>{title}</h2>
        <div className={s.content}>
          <ScrollText text={textContent} height={197} />
        </div>
      </>
    )
  }, [textContent]);

  return (
    <div className={s.intro}>
      {tab}
      {text}
    </div>
  )
}