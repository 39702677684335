import classNames from 'classnames';
import s from './GradientButton.module.css';

interface GradientButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'warning';
}

export default function GradientButton({ color = 'primary', children, style }: GradientButtonProps) {
  return (
    <div className={classNames(s.gradientButton, {
      [s.primary]: color === 'primary',
      [s.warning]: color === 'warning',
    })}
      style={style}
    >
      <div className={classNames(s.borderBg)} />
      <div className={classNames('teagarden-text-gradient', s.gradientButtonContent)}>{children}</div>
    </div>
  )
}