import WarningIcon from '../../assets/images/common/warning.png'
import s from './WeatherForecast.module.css'
import classNames from 'classnames'
import { useMemo } from 'react'
import CloudyIcon from '../../assets/images/common/cloudy-icon.svg'
import SunIcon from '../../assets/images/common/sun-icon.svg'
import CloudIcon from '../../assets/images/common/cloud-icon.svg'
import useDataProvider from '../hooks/use-data-provider'

const ICONS: { [key: string]: any } = {
  '多云': CloudyIcon,
  '晴': SunIcon,
  '阴': CloudIcon,
}

export default function WeatherForecast() {

  const { data: forecastItems, loading } = useDataProvider({
    api: '/weather/get-weather',
    transform: (d: DataResponse<Weather>) => {
      return d.result.forecasts[0].casts.slice(0, 3).map((item) => {
        return {
          icon: ICONS[item.dayweather],
          date: item.date,
          weather: `${item.dayweather} ${item.daywind}风 ${item.daypower} 级`,
          temperature: `${item.daytemp} - ${item.nighttemp}`,
        }
      })
    },
  })

  const { data: warningData } = useDataProvider({
    api: '/weather/is-warning-exists',
    transform: (d: DataResponse<WeatherWarning>) => {
      return {
        content: d?.result?.content,
        time: d?.result?.time,
      }
    }
  })

  const warning = useMemo(() => {
    if (!warningData?.content) return null
    return (
      <div className={s.warning}>
        <img src={WarningIcon} className={s.icon} alt="" />
        <div className={classNames(s.content, 'teagarden-text-gradient')}>
          <p>{warningData?.content}</p>
          <p className={s.time}>告警时间：{warningData?.time}</p>
        </div>
      </div>
    )
  }, [warningData])

  const forecastList = useMemo(() => {
    return (
      <div className={s.forecastList}>
        {
          forecastItems?.map((item, idx) => {
            return (
              <div className={s.forecastItem} key={`forecast-item-${idx}`}>
                <div className={s.brief}>
                  <img src={item.icon} className={s.icon} alt="" />
                  <p className={s.date}>{item.date}</p>
                </div>
                <p>{item.weather}</p>
                <p>
                  <span className={s.temperature}>{item.temperature}</span>{' '}℃
                </p>
              </div>
            )
          })
        }
      </div>
    )
  }, [forecastItems])

  return (
    <div className={s.weatherForecast}>
      {warning}
      <h3 className={classNames(s.forecastTitle, 'teagarden-text-gradient')}>未来3天天气预报</h3>
      {forecastList}
    </div>
  )
}
