import { useCallback, useMemo } from "react"
import s from "./AquacultureSituation.module.css";
import FishIcon from '../../assets/images/common/fish-icon.png';
import FrogIcon from '../../assets/images/common/frog-icon.png';
import useDataProvider from '../hooks/use-data-provider';

interface IData {
  icon: any;
  title: string;
  data: number;
  unit: string;
}

export default function AquacultureSituation() {
  const { data: batrachishItems } = useDataProvider({
    api: '/batrachish/list',
    transform: (d: DataResponse<BatrachishRecords>) => {
      return d.result.records.map((rec) => {
        return {
          riceFrogCount: rec.riceFrogCount,
          riceFishCount: rec.riceFishCount,
          riceFrogArea: rec.riceFrogArea,
          riceFishArea: rec.riceFishArea,
          riceFrogTotal: rec.riceFrogTotal,
          riceFishTotal: rec.riceFishTotal,
        };
      })
    },
  });
  const riceFrogArea = batrachishItems?.reduce((acc, cur) => acc + cur.riceFrogArea, 0) || 0;
  const riceFishArea = batrachishItems?.reduce((acc, cur) => acc + cur.riceFishArea, 0) || 0;
  const riceFrogTotal = batrachishItems?.reduce((acc, cur) => acc + cur.riceFrogTotal, 0) || 0;
  const riceFishTotal = batrachishItems?.reduce((acc, cur) => acc + cur.riceFishTotal, 0) || 0;

  const dataSource: IData[] = useMemo(() => [
    {
      icon: FrogIcon,
      title: '稻蛙养殖总面积',
      data: riceFrogArea,
      unit: '亩'
    },
    {
      icon: FishIcon,
      title: '稻鱼养殖总面积',
      data: riceFishArea,
      unit: '亩'
    },
    {
      icon: FrogIcon,
      title: '稻蛙养殖总数',
      data: riceFrogTotal,
      unit: '尾'
    },
    {
      icon: FishIcon,
      title: '稻鱼养殖总数',
      data: riceFishTotal,
      unit: '尾'
    }
  ], [batrachishItems])

  const item = useCallback(({ icon, title, data, unit }: IData) => {
    return (
      <div className={s.item}>
        <img src={icon} className={s.icon} alt="" />
        <div className={s.text}>
          <h1 className={s.title}>{title}</h1>
          <p className={s.data}>
            <span>{data.toLocaleString()}</span> {unit}
          </p>
        </div>
      </div>
    )
  }, [])

  return (
    <div className={s.aquacultureSituation}>
      {dataSource.map((data, idx) => <div key={`as-item-${idx}`}>{item(data)}</div>)}
    </div>
  )
}