import s from './TitleWithIcon.module.css';
import TitleIcon from '../../assets/images/common/title-icon.png';
import classNames from 'classnames';

interface TitleWithIconProps {
  title: string;
  dir?: "left" | "right";
  style?: React.CSSProperties;
}

export default function TitleWithIcon({ title, dir = "left", style }: TitleWithIconProps) {
  return (
    <div className={classNames(s.titleWithIcon, { [s.reverse]: dir === "right" })} style={style}>
      <img className={s.icon} src={TitleIcon} alt="" />
      <span className={s.text}>{title}</span>
    </div>
  )
}