import s from './BubbleAnimation.module.css'
import classNames from 'classnames'

export interface BubbleAnimationProps {
  width?: number
  height?: number
  count?: number
  className?: string
  color?: string
}

const DEFAULT_DURATION = 6

export default function BubbleAnimation(
  { width, height, count = 40, className, color }: BubbleAnimationProps,
) {
  return (
    <div className={classNames(className, s.bubbleAnimation)} style={{ width, height }}>
      {Array.from({ length: count }).map((_, index) => {
        const size = Math.floor(Math.random() * 12)
        const left = Math.floor(Math.random() * 100)
        return (
          <div
            key={index}
            className={s.bubble}
            style={{
              width: size,
              height: size,
              left: `${left}%`,
              animationDuration: `${DEFAULT_DURATION}s`,
              animationDelay: `${Math.random() * DEFAULT_DURATION}s`,
              opacity: Math.random() * 0.5 + 0.2,
              ...color ? { backgroundColor: color } : null,
            }}
          >
          </div>)
      })}
    </div>
  )
}
