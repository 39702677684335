import useDataProvider, {
  TransformFunction,
  UseDataProviderProps,
} from '../hooks/use-data-provider'
import React, {
  useEffect,
  useState,
} from 'react'
import { DEFAULT_PIE_CHART_OPTIONS } from '../../constants/chart-options'
import {
  clone,
  identity,
} from 'ramda'
import ReactEcharts from 'echarts-for-react'
import bg from '../../assets/images/chart/chart-bg.svg'

export interface PieChartOverviewProps<T> {
  optionsUpdate?: (options: any) => any
  dataProvider: UseDataProviderProps<T>
}

export default function PieChartOverview<T = any>(
  { optionsUpdate = identity, dataProvider }: PieChartOverviewProps<T>,
) {

  const [chartOptions, setChartOptions] = useState(DEFAULT_PIE_CHART_OPTIONS)

  const { loading, data, title } = useDataProvider<T>(dataProvider)

  useEffect(() => {
    let nextOptions = optionsUpdate?.(DEFAULT_PIE_CHART_OPTIONS)
    nextOptions.title.text = title
    nextOptions.series[0].data = data
    setChartOptions(clone(nextOptions))
  }, [data, optionsUpdate])

  return (
    <div
      style={{
        background: `url(${bg}) no-repeat`,
        backgroundSize: '154px',
        backgroundPosition: 'center',
        padding: '8px 0',
        margin: '-16px 0',
      }}>
      <ReactEcharts
        showLoading={loading}
        style={{ width: 360, height: 115 }}
        option={chartOptions}
        notMerge={true}
        lazyUpdate={true}
      />
    </div>
  )
}
