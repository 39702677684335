import s from './HorizontalDataTable.module.css'
import ProgressBar from './ProgressBar'
import { useMemo } from 'react'

export interface DataItem {
  name: string
  value: number
}

export interface HorizontalDataTableProps {
  titleWidth?: number
  data: DataItem[]
  unit: string
}

export default function HorizontalDataTable(
  { titleWidth = 48, data, unit }: HorizontalDataTableProps,
) {
  const maxValue = useMemo(() => {
    return Math.max(...data.map(d => d.value))
  }, [])
  return (
    <table className={s.horizontalDataTable}>
      <tbody>
        {data.map((item, index) => {
          const ratio = item.value / maxValue
          return <tr key={index}>
            <th style={{ width: titleWidth }}>{item.name}</th>
            <td><ProgressBar ratio={ratio} /></td>
            <td className={s.value}>{item.value}<span className={s.unit}>{unit}</span></td>
          </tr>
        })}
      </tbody>
    </table>
  )
}
