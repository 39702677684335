import useNavDataProvider from '../hooks/use-nav-data-provider'
import s from './NavBar.module.css'
import NavItem from './NavItem'

export default function NavBar() {
  const items = useNavDataProvider()

  return (
    <div className={s.navBar}>
      {
        items.map((item, index) => {
          return (
            <NavItem
              key={`nav-item-${index}`}
              dir="column"
              active={item.active}
              src={item.src}
              label={item.label}
              onClick={item.onClick}
            />
          )
        })
      }
    </div>
  )
}
