import React from 'react'
import ReactDOM from 'react-dom/client'
import router from '../src/Router'
import { RouterProvider } from 'react-router-dom'
import 'swiper/css'
import './index.css'
import Modal from 'react-modal'

Modal.setAppElement('#root')

const rootElement = document.getElementById('root')!
const root = ReactDOM.createRoot(rootElement)

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)
