import React, { useState } from 'react'
import WeeklyFeedOverview from './components/WeeklyFeedOverview'
import FarmDataOverview from './components/FarmDataOverview'
import VisitorTrafficOverview from './components/VisitorTrafficOverview'
import ProductMarketingOverview from './components/ProductMarketingOverview'
import ProductTrendingOverview from './components/ProductTrendingOverview'
import ProductTrendingLineOverview from './components/ProductTrendingLineOverview'
import IncomeOverview from './components/IncomeOverview'
import ProductPriceTrendingOverview from './components/ProductPriceTrendingOverview'
import AnnualFarmProductionOverview from './components/AnnualFarmProductionOverview'
import TouristOverview from './components/TouristOverview'
import RiceConsumptionOverview from './components/RiceConsumptionOverview'
import BubbleAnimation from './components/BubbleAnimation'
import Modal from 'react-modal'
import FieldProductionProcessModal from './components/modals/FieldProductionProcessModal'
import AgriculturalProductTraceability from './components/AgriculturalProductTraceability'
import ContentModal from './components/modals/ContentModal'
import DetailContent from './components/DetailContent'
import DetailContent1 from './components/details/DetailContent1'

export default function Chart() {

  return (
    <div style={{ background: '#102922', display: 'flex', gap: 16, flexDirection: 'column' }}>
      <div>
        <ContentModal
          trigger={<button>content modal</button>}
          content={<DetailContent1 />}
        />
      </div>
      <AgriculturalProductTraceability />
      <div>
        <FieldProductionProcessModal
          trigger={<button>Open Modal</button>}
        />
      </div>
      <div style={{ position: 'fixed', bottom: 0, height: 200 }}>
        <BubbleAnimation />
      </div>
      <RiceConsumptionOverview />
      <TouristOverview />
      <AnnualFarmProductionOverview />
      <ProductPriceTrendingOverview />
      <IncomeOverview />
      <div>
        <ProductTrendingOverview />
        <ProductTrendingLineOverview />
      </div>
      <ProductMarketingOverview />
      <VisitorTrafficOverview />
      <FarmDataOverview />
      <WeeklyFeedOverview />
    </div>
  )
}
