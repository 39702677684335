import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import s from './Tab.module.css';
import classNames from 'classnames';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

interface TabData {
  name: string;
  content: JSX.Element;
}

interface TabProps {
  active?: string;
  style?: React.CSSProperties;
  type?: 'default' | 'swiper';
  items: TabData[];
  onChange?: (name: string) => void;
}

interface TabImperativeHandle { }

interface TabItemProps {
  name: string;
  active?: string;
  style?: React.CSSProperties;
  handleClick?: (name: string) => void
}

function TabItem({ name, active, style, handleClick }: TabItemProps) {
  return (
    <div
      className={classNames("teagarden-pointer-item", s.tabItem, { [s.active]: active === name })}
      onClick={() => handleClick?.(name)}
      style={style}
    >
      {name}
    </div>
  )
}

function Navigator() {
  const swiper = useSwiper();
  return (
    <div className={s.navigation}>
      <div
        className={classNames(s.expand, s.left)}
        onClick={() => { swiper.slidePrev() }}
      />
      <div
        className={classNames(s.expand, s.right)}
        onClick={() => { swiper.slideNext() }}
      />
    </div>
  )
}

function TabSwiper({ active, items, handleClick }: { active?: string; items: TabData[], handleClick?: (name: string) => void }) {
  return (
    <Swiper
      slidesPerView={5.8}
      slidesOffsetBefore={8}
      slidesPerGroup={4}
       width={window.innerWidth}
    >
      <>
        {items?.map((item, idx) => {
          const { name } = item;
          return (
            <SwiperSlide key={`tab-swiper-${idx}`}>
              <TabItem
                name={name}
                active={active}
                handleClick={() => handleClick?.(name)}
              />
            </SwiperSlide>
          )
        })}
      </>
      <Navigator />
    </Swiper>
  )
}

const Tab = forwardRef<TabImperativeHandle, TabProps>(({ active: initialActive, style, type, items, onChange }, ref) => {
  const [active, setActive] = useState(initialActive || items[0].name);

  const tab = useMemo(() => {
    return (
      <div className={s.tab} style={style}>
        {items?.map((item) =>
          <TabItem
            name={item.name}
            active={active}
            key={`tab-item-${item.name}`}
            handleClick={(name) => {
              setActive(name);
              onChange?.(name);
            }}
          />)}
      </div>
    )
  }, [active]);

  const swiperTab = useMemo(() => {
    return (
      <div className={s.tab} style={style}>
        <TabSwiper
          items={items}
          active={active}
          handleClick={(name) => {
            setActive(name);
            onChange?.(name);
          }}
        />
      </div>
    )
  }, [active]);

  const content = useMemo(() => {
    const item = items.find((item) => item.name === active);
    if (!item) return null;

    return item.content;
  }, [items, active]);

  return (
    <div>
      {type == 'swiper' ? swiperTab : tab}
      {content}
    </div>
  )
})

export default Tab;