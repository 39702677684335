import classNames from 'classnames';
import { useMemo, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import s from './SvgIcon.module.css';
import useWindowZoom from '../../hooks/use-window-zoom';
import Icons, { IconName } from './Icons';

export interface SvgIconProps {
  className?: string;
  name?: IconName;
  href?: string;
  size?: number;
  color?: string;
  style?: any;
}

export default function SvgIcon(props: SvgIconProps) {
  const zoom = useWindowZoom();
  const { className = '', href, size = 14, color, style, name } = props;
  const [svgText, setSvgText] = useState('');

  const svgHref = useMemo(() => {
    if (href) return href;
    return Icons[name as IconName];
  }, [name, href]);

  useAsync(async () => {
    try {
      const svgText = await fetch(svgHref).then((resp) => resp.text());
      setSvgText(svgText);
    } catch (ex) { }
  }, [svgHref]);

  return (
    <span
      className={classNames(s.svgIcon, className)}
      style={{ width: `${size * zoom}px`, height: `${size * zoom}px`, color, ...style }}
      dangerouslySetInnerHTML={{ __html: svgText }}
    />
  );
}
