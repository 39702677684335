import { Swiper, SwiperClass, SwiperSlide, useSwiper } from 'swiper/react';
import s from './ImgSwiper.module.css';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames';

interface PaginationImperativeHandle {
  setActive: (idx: number) => void;
}

interface PaginationProps {
  total: number;
}

const Pagination = forwardRef<PaginationImperativeHandle, PaginationProps>(({ total }, ref) => {
  const swiper = useSwiper();
  const [active, setActive] = useState<number>(0);

  useImperativeHandle(ref, () => {
    return {
      setActive
    };
  });

  return (
    <div className={s.pagination}>
      {
        Array.from({ length: total }, (_, idx) => idx).map((num, idx) => {
          return <div
            key={`img-swiper-pagination-${idx}`}
            className={classNames(s.paginationItem, { [s.active]: active === idx })}
            onClick={() => {
              setActive(idx);
              swiper.slideTo(idx)
            }}
          />
        })
      }
    </div>
  )
});

interface ImgSwiperProps {
  data: string[];
  handleSlideChange?: (activeIndex: number) => void;
}

export default function imgSwiper({ data, handleSlideChange }: ImgSwiperProps) {
  const paginationRef = useRef<PaginationImperativeHandle>(null);

  return (
    <div className={s.imgSwiper}>
      <Swiper
        navigation
        spaceBetween={8}
        onSlideChangeTransitionEnd={(swiper: SwiperClass) => {
          const { activeIndex } = swiper;
          paginationRef.current?.setActive(activeIndex);
          handleSlideChange?.(activeIndex);
        }}
      >
        {
          data.map((img, idx) => {
            return <SwiperSlide key={`intro-swiper-${idx}`}><img src={img} alt="" /></SwiperSlide>
          })}
        <Pagination ref={paginationRef} total={data.length} />
      </Swiper>
    </div>
  )
}