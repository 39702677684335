import {
  CHART_GREEN_LINEAR_GRADIENT,
  CHART_YELLOW_LINEAR_GRADIENT,
  GREEN_LIGHT,
  YELLOW_LIGHT,
} from '../../constants/colors'
import PieChartOverview from './PieChartOverview'
import { ChartDataItem } from '../hooks/use-data-provider'

const WeeklyFeedOverview = () => {
  return (
    <div>
      <PieChartOverview
        optionsUpdate={(options) => {
          options.series[0].label.formatter = '{name|{b}}\n{value|{c}} {unit|户}'
          return options
        }}
        dataProvider={{
          api: '/agriculture/list',
          transform(d: DataResponse<AgricultureRecords>) {
            const chartData: ChartDataItem[] = d.result.records.map((rec) => {
              const itemStyle = rec.title.indexOf('茶园') !== -1 ?
                { color: CHART_GREEN_LINEAR_GRADIENT, borderColor: GREEN_LIGHT, } :
                { color: CHART_YELLOW_LINEAR_GRADIENT, borderColor: YELLOW_LIGHT }
              return {
                name: rec.title,
                value: Number(rec.peasantHouseholdCount),
                itemStyle
              };
            })

            // TODO: 是否可以删除？？@阿秋
            // const total = chartData.reduce((acc: number, item: any) => acc + item.value, 0)
            // const gapValue = total * 0.01
            // const gapItem = {
            //   value: gapValue,
            //   name: '',
            //   itemStyle: { color: 'transparent' },
            //   label: { show: false },
            // }
            // chartData.splice(1, 0, gapItem)
            // chartData.splice(3, 0, gapItem)

            return chartData
          },
          getTitle: (data) => {
            const total = data.reduce((acc: number, item: any) => acc + item.value, 0)
            return `${total}户\n总计`
          },
        }} />
    </div>
  )
}

export default WeeklyFeedOverview
