import React, {
  cloneElement,
  isValidElement,
  ReactElement,
} from 'react'
import { useToggle } from 'react-use'
import Modal from 'react-modal'
import useWindowZoom from '../../hooks/use-window-zoom'
import s from './ModalWithTrigger.module.css'
import classNames from 'classnames'

// experimental
export type ClickableElement = ReactElement<{ onClick?: () => void }>;

export interface ModalWithTriggerProps extends Omit<ReactModal.Props, 'isOpen' | 'children'> {
  trigger?: ClickableElement | null;
  isOverlay?: boolean;
  clickButtonToggleModal?: boolean;
  children?: React.ReactNode | ((toggle: (opened: boolean) => void) => React.ReactNode);
  handleClose?: () => void;
  handleOpen?: () => void;
}

export default function ModalWithTrigger(
  {
    trigger,
    children,
    isOverlay,
    clickButtonToggleModal,
    handleOpen,
    handleClose,
    ...modalProps
  }: ModalWithTriggerProps,
) {
  const [opened, toggle] = useToggle(false)
  const zoom = useWindowZoom()
  const { overlayClassName, ...resetModalProps } = modalProps

  const clonedTrigger = isValidElement(trigger) ?
    cloneElement(trigger, { onClick: () => toggle(clickButtonToggleModal ? !opened : true) }) :
    null

  return (
    <>
      {clonedTrigger}
      <Modal
        isOpen={opened}
        onRequestClose={() => toggle(false)}
        overlayClassName={classNames(
          s.overlay,
          { [s.active]: isOverlay },
          overlayClassName,
        )}
        style={{
          content: { zoom },
        }}
        {...resetModalProps}
        onAfterClose={handleClose}
        onAfterOpen={handleOpen}
      >
        {typeof children === 'function' ? children(toggle) : children}
        <div className={classNames(s.border, s.rect, s.left)} />
        <div className={classNames(s.border, s.right)} />
        <div className={classNames(s.border, s.rect, s.bRight)} />
        <div className={classNames(s.border, s.bLeft)} />
      </Modal>
    </>
  )
}
