import HorizontalDataTable from './HorizontalDataTable'

export default function AnnualFarmProductionOverview() {
  return (
    <div>
      <span style={{color: '#fff'}}>
        本年养殖产品产量
      </span>
      <HorizontalDataTable
        unit={'斤'}
        data={[
          { name: '贡米', value: 73953 },
          { name: '茶叶', value: 64767 },
          { name: '稻蛙', value: 52375 },
          { name: '草鱼', value: 48235 },
          { name: '鲫鱼', value: 32757 },
          { name: '鲤鱼', value: 17650 },
          { name: '鲢鳙', value: 9556 },
        ]} />
    </div>
  )
}
