import DetailContent, { ContentBlock } from '../DetailContent'
import detailMedia1 from '../../../assets/images/detail-content/detail-media-1.png'
import Divider from '../Divider'
import classNames from 'classnames'
import SvgIcon from '../svg-icon/SvgIcon'
import SubTitleWithIcon from '../SubTitleWithIcon'

export default function DetailContent2(
  {
    detail
  }: {
    detail?: HotRestaurant
  }
) {
  return (
    <DetailContent content={
      <div>
        <span
          style={{ fontSize: 18 }}
          className={classNames(
            'teagarden-text-gradient',
            'green',
          )}
        >{detail?.restaurantName}</span>
        <p style={{
          fontSize: 12,
          fontWeight: 'normal',
        }}
          className={classNames(
            'teagarden-text-gradient',
            'green',
          )}
        >
          <SvgIcon name="local" style={{ marginRight: 4 }} />
          {detail?.address}
        </p>
        <p style={{
          fontSize: 12,
          fontWeight: 'normal',
        }}
          className={classNames(
            'teagarden-text-gradient',
            'green',
          )}
        >
          <SvgIcon name="price" style={{ marginRight: 4 }} />
          {`人均：￥${detail?.perCapitaConsumption}`}
        </p>
        <Divider />
        <div style={{ display: 'flex', gap: 12, flexDirection: 'column' }}>
          <ContentBlock
            icon={<SubTitleWithIcon title="特色美食" />}
            content={detail?.featuredCuisine}
          />
          <ContentBlock
            icon={<SubTitleWithIcon title="口感" />}
            content={detail?.texture}
          />
          <ContentBlock
            icon={<SubTitleWithIcon title="美食介绍" />}
            content={detail?.foodIntroduction}
          />
          <ContentBlock
            icon={<SubTitleWithIcon title="原料介绍" />}
            content={detail?.introductionToRawMaterials}
          />
        </div>
      </div>
    } media={
      <img src={detailMedia1} alt="temple" />
    } />
  )
}
