import s from './HistoryCultureModal.module.css'
import { ModalWithTriggerProps } from './ModalWithTrigger'
import Tab from '../Tab'
import {
  useEffect,
  useMemo,
  useState,
} from 'react'
import useDataProvider from '../../hooks/use-data-provider'
import ContentModal from './ContentModal'

interface Props extends ModalWithTriggerProps {
  active?: '贡米' | '贡茶';
  data?: {
    picture: string;
    description: string;
  };
  onTabChange?: (name: string) => void;
}

const ASSET_PATH = window.APP_CONFIG.REACT_APP_ASSET_PREFIX_PATH

export default function HistoryCultureModal({ data, onTabChange, active, trigger }: Props) {
  const dataSource = useMemo(
    () => [
      { name: '贡米', type: 1 },
      { name: '贡茶', type: 2 }],
    [],
  )

  const items = useMemo(() => dataSource?.map((item, idx) => {
    return {
      ...item,
      content: (
        <div className={s.cultureItem} key={`history-culture-item-${idx}`}>
          {data?.description}
        </div>
      ),
    }
  }), [data])

  return (
    <ContentModal
      className={s.contentModal}
      trigger={trigger}
      clickButtonToggleModal
      isOverlay
      content={
        <div className={s.historyCultureModal}>
          <Tab
            active={active}
            items={items}
            onChange={onTabChange}
          />
          {data?.picture &&
            // TODO:
            <video className={s.video}
              poster={`${ASSET_PATH}/${data?.picture}`}
              src="http://101.132.99.228:8899/zx-smart-agriculture/sys/common/static/temp/%E9%9C%93%E8%99%B9%E7%81%AF%E5%8F%91%E5%85%89%E5%85%89%E5%9C%88sound%E5%A3%B0%E9%9F%B3%E5%9B%BE%E5%BD%A2%E5%8A%A8%E7%94%BB-AI%E4%BA%BA%E5%B7%A5%E6%99%BA%E8%83%BD-%E5%8F%AF%E5%BE%AA%E7%8E%AF_1725505155104.mp4"
              width={'100%'}
              height={'100%'}
              controls
            />
          }
        </div>
      }
    />
  )
}