import classNames from 'classnames';
import s from './Switch.module.css';
import { useState } from 'react';

export default function Switch({ label }: { label: string }) {
  const [active, setActive] = useState(false);

  return (
    <div className={s.switch} onClick={() => setActive(!active)}>
      <span>{label}</span>
      <div className={s.content}>
        <div className={classNames(s.btn, { [s.active]: active })} />
      </div>
    </div>
  )
}
