import classNames from 'classnames'
import Header from './Header'
import s from './Layout.module.css'
import NavBar from './NavBar'
import useWindowZoom from '../hooks/use-window-zoom'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import GradientText from './GradientText'

interface LayoutProps {
  bg: string;
  left: ReactNode;
  right: ReactNode;
  children?: ReactNode;
}

interface PanelProps {
  active?: boolean;
  dir: 'left' | 'right';
  children?: ReactNode;
}

function Panel({ dir, active = true, children }: PanelProps) {
  const zoom = useWindowZoom()

  return (
    <div
      className={classNames(s.panel, s[dir], { [s.active]: active })}
      style={{ transform: `scale(${zoom})`, top: 45 * zoom }}
    >
      {children}
    </div>
  )
}

export default function Layout({ bg, left, right, children }: LayoutProps) {
  const zoom = useWindowZoom()
  const [leftHide, setLeftHide] = useState(false)
  const [rightHide, setRightHide] = useState(false)

  const bottomPanel = useMemo(() => {
    return (
      <div className={s.bottomPanel} style={{ zoom }}>
        <div className={s.date}>
          <GradientText>2024年07月25日</GradientText>
          <GradientText gradient="linear-gradient(180deg, #26C2A1 0%, #FDFFFF 100%)">星期二</GradientText>
        </div>
        <div className={s.weather}>
          <div>
            <span className={classNames(s.icon, s.weatherIcon)} /> <GradientText>晴</GradientText>
          </div>
          <div style={{ cursor: 'pointer' }}>
            <span className={classNames(s.icon, s.hideIcon)} /> <GradientText>隐藏UI</GradientText>
          </div>
        </div>
      </div>
    )
  }, [zoom]);

  return (
    <div className={s.layout} style={{ backgroundImage: `url(${bg})` }}>
      <Header />
      <div>
        {!leftHide && <Panel dir="left" children={left} />}
        {!rightHide && <Panel dir="right" children={right} />}
        <div
          className={s.navBar}
          style={{ transform: `scale(${zoom}) translateX(-50%)`, bottom: 60 * zoom }}>
          <NavBar />
        </div>
        {children}
      </div>
      {bottomPanel}
      <div
        className={classNames(s.expand, s.left, { [s.hide]: leftHide })}
        onClick={() => setLeftHide(!leftHide)}
        style={{ left: leftHide ? 32 * zoom : 440 * zoom }}
      />
      <div
        className={classNames(s.expand, s.right, { [s.hide]: rightHide })}
        onClick={() => setRightHide(!rightHide)}
        style={{ right: rightHide ? 32 * zoom : 440 * zoom }}
      />
    </div>
  )
}
