import s from './App.module.css'
import mask from '../assets/images/guide/mask.png'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import NavItem from './components/NavItem'
import startImg from '../assets/images/guide/start.png'
import BubbleAnimation from './components/BubbleAnimation'

import 'normalize.css'
import Header from './components/Header'
import useNavDataProvider from './hooks/use-nav-data-provider'
import useWindowZoom from './hooks/use-window-zoom'
import classNames from 'classnames'

function App() {
  const navigate = useNavigate()
  const zoom = useWindowZoom()

  const items = useNavDataProvider()

  const navBar = useMemo(() => {
    return (
      <div className={s.navBar}>
        <div
          className={classNames("teagarden-pointer-item", s.mainEntryWrapper)}
          onClick={() => navigate('/intelligent-agriculture')}
        >
          <BubbleAnimation count={30} className={s.mainEntryBubbles} />
          <div className={s.mainEntryRotating}></div>
        </div>
        <img className={s.start} src={startImg} />
        <div className={s.navBarBg} />
        <div className={s.navItems}>
          {
            items.map((item, index) => {
              const mt = index === 1 ? 64 : -20
              return (
                <NavItem
                  key={index}
                  src={item.src}
                  label={item.label}
                  active={true}
                  animation={false}
                  styles={{ marginTop: `${mt}px` }}
                  onClick={item.onClick}
                />
              )
            })
          }
        </div>
      </div>
    )
  }, [zoom])

  return (
    <div className={s.guidePage}>
      <div className={s.content} style={{ zoom }}>
        <Header />
        {navBar}
      </div>
    </div>
  )
}

export default App
