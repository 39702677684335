import s from './HistoryCulture.module.css';
import PlaceholderImg from '../../assets/images/test/history-video-cover.png';
import classNames from 'classnames';
import SvgIcon from './svg-icon/SvgIcon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import HistoryCultureModal from './modals/HistoryCultureModal';
import Tab from './Tab';
import useDataProvider from '../hooks/use-data-provider';

type tabActive = '贡米' | '贡茶';

export default function HistoryCulture() {
  const [type, setType] = useState(1);

  const { data: cultureData, fetchData } = useDataProvider({
    api: `/historicalcultural/smartHistoricalCultural/find-by-type?type=${type}`,
    transform: (d: DataResponse<HistoryCultureData>) => {
      return {
        picture: d.result?.picture,
        description: d.result?.description,
      }
    },
  })

  useEffect(() => {
    fetchData();
  }, [type]);

  const onChange = useCallback((name: string) => {
    const t = name == '贡米' ? 1 : 2
    setType(t)
  }, []);

  const cover = useCallback((active: tabActive) => {
    return (
      <HistoryCultureModal
        active={active}
        data={cultureData}
        onTabChange={onChange}
        trigger={
          <div className={s.imgWrapper}>
            <SvgIcon name="play" className={s.play} size={56} />
            <SvgIcon name="fullscreen" className={s.fullscreen} size={24} />
            {/* TODO: picture */}
            <img src={PlaceholderImg} alt="" className={s.coverImg} />
          </div>
        }
      />
    )
  }, [cultureData]);

  const items = useMemo(() => ["贡米", "贡茶"].map((item, idx) => {
    return {
      name: item,
      content: (
        <div key={`history-${item}-culutre-${idx}`}>
          {cover(item as tabActive)}
          <p className={classNames('teagarden-text-overflow', s.text)}>{cultureData?.description}</p>
        </div>
      )
    }
  }), [cultureData]);

  return (
    <div className={s.historyCulture}>
      <Tab
        items={items}
        onChange={onChange}
        style={{ justifyContent: "flex-end" }}
      />
    </div>
  )
}