import { createBrowserRouter } from 'react-router-dom'

import App from './pages/App'
import Chart from './pages/Chart'
import IntelligentAgriculture from './pages/IntelligentAgriculture'
import AgricultureJourney from './pages/AgricultureJourney'
import Traceability from './pages/Traceability'
import Test from './pages/Test'

export default createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/test',
    element: <Test />,
  },
  {
    path: '/intelligent-agriculture',
    element: <IntelligentAgriculture />,
  },
  {
    path: '/agriculture-journey',
    element: <AgricultureJourney />,
  },
  {
    path: '/traceability',
    element: <Traceability />,
  },
  {
    path: '/chart',
    element: <Chart />,
  },
])
