import classNames from 'classnames';
import s from './Dropdown.module.css';
import { useEffect, useState } from 'react';
import useWindowZoom from '../hooks/use-window-zoom';

interface DropdownProps {
  active?: boolean;
  icon?: string;
  label: string;
  type?: 'default' | 'gradient';
  content?: React.ReactNode;
}

export function GradientDropdown({ icon, label, content }: DropdownProps) {
  const [active, setActive] = useState(false);

  return (
    <div className={classNames(s.dropdown, s.gradient, { [s.active]: active })}>
      <div className={s.borderBg} />
      <div
        className={s.button}
        onClick={() => { setActive(!active) }}
      >
        <span className={s.icon} style={{ backgroundImage: `url(${icon})` }} />
        <span className={classNames(s.label, "teagarden-text-gradient")}>{label}</span>
        <span className={classNames(s.icon, s.arrowIcon, { [s.active]: active })} />
      </div>
      {active && <div className={s.content}>{content}</div>}
    </div >)
}

export function NormalDropdown({ active: initialActive, label, content }: DropdownProps) {
  const [active, setActive] = useState(initialActive);
  const isSimpleButton = !content;

  useEffect(() => {
    setActive(initialActive);
  }, [initialActive]);

  return (
    <div onClick={() => { isSimpleButton && setActive(!active) }}>
      <div className={classNames(s.dropdown, { [s.active]: isSimpleButton && active })}>
        <div
          className={s.button}
          onClick={() => { !isSimpleButton && setActive(!active) }}
        >
          <span className={s.label}>{label}</span>
          {!isSimpleButton && <span className={classNames(s.icon, s.arrowIcon, { [s.active]: active })} />}
        </div>
        {active && !isSimpleButton && <div className={s.content}>{content}</div>}
      </div>
      <div className={classNames(s.border, s.topBorder)} />
      <div className={classNames(s.border, s.bottomBorder)} />
    </div>
  )
}

export default function Dropdown(props: DropdownProps) {
  const zoom = useWindowZoom()

  return (
    <div className={classNames("teagarden-pointer-item", s.dropdowWrapper)} style={{ zoom }}>
      {props.type === 'gradient' ? <GradientDropdown {...props} /> : <NormalDropdown {...props} />}
    </div>
  )
}