import React from 'react'
import {
  CHART_GREEN_LINEAR_GRADIENT,
  CHART_RED_LINEAR_GRADIENT,
  CHART_SILVER_LINEAR_GRADIENT,
  CHART_YELLOW_LINEAR_GRADIENT,
  GRAY_1,
  GREEN_LIGHT,
  RED_LIGHT,
  YELLOW_LIGHT,
} from '../../constants/colors'
import PieChartOverview from './PieChartOverview'
import { ChartDataItem } from '../hooks/use-data-provider'

export default function ProductTrendingOverview() {
  return (
    <div>
      <PieChartOverview
        optionsUpdate={(options) => {
          options.series[0].label.formatter = '{name|{b}} {value|{c}} {unit|%}'
          options.title.textStyle.fontSize = 14
          return options
        }}
        dataProvider={{
          api: `/product/bread-proportion`,
          transform(d: DataResponse<ProportionBrief[]>) {
            const styles = [
              { color: CHART_GREEN_LINEAR_GRADIENT, borderColor: GREEN_LIGHT },
              { color: CHART_SILVER_LINEAR_GRADIENT, borderColor: GRAY_1 },
              { color: CHART_YELLOW_LINEAR_GRADIENT, borderColor: YELLOW_LIGHT },
              { color: CHART_RED_LINEAR_GRADIENT, borderColor: RED_LIGHT },
            ]
            const chartData: ChartDataItem[] = d.result.map((item, idx) => {
              return {
                name: item.name,
                value: item.value,
                itemStyle: styles[idx % (styles.length + 1)]
              }
            }) || [];

            const total = [].reduce((acc: number, item: any) => acc + item.value, 0) || 100
            const gapValue = total * 0.01
            const gapItem = {
              value: gapValue,
              name: '',
              itemStyle: { color: 'transparent' },
              label: { show: false },
            }
            for (let i = 0; i < 4; i++) {
              chartData.splice(i * 2 + 1, 0, gapItem)
            }

            return chartData
          },
          getTitle: () => '出库商品',
        }} />
    </div>
  )
}
