import useDataProvider from '../hooks/use-data-provider';
import HorizontalDataTable from './HorizontalDataTable'

export default function RiceConsumptionOverview() {
  const { data } = useDataProvider({
    api: '/trait/consumption',
    transform: (d: DataResponse<RiceConsumptionOrOutputOfBreedingProducts[]>) => {
      return d.result
        ?.map(item => ({
          name: item.name,
          value: item.traitYield,
        }))
    },
  });

  return data ? (
    <div>
      <HorizontalDataTable
        unit={'个'}
        data={data} />
    </div>
  ) : null
}
