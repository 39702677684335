import ReactEcharts from 'echarts-for-react'
import * as echarts from 'echarts'
import {
  CHART_GREEN_BG_LINEAR_GRADIENT,
  CHART_LIGHT_BLUE_LINEAR_GRADIENT,
  CHART_LIGHT_GREEN_LINEAR_GRADIENT,
  CHART_LIGHT_RED_LINEAR_GRADIENT,
  CHART_LIGHT_YELLOW_LINEAR_GRADIENT,
  GREEN_LINE,
  WHITE_ALPHA_20,
  WHITE_ALPHA_60,
} from '../../constants/colors'
import { DINGTALK_JINBUTI } from '../../constants/font'
import useDataProvider from '../hooks/use-data-provider'
import {
  useEffect,
  useState,
} from 'react'
import { EChartsOption } from 'echarts-for-react/src/types'
import { clone } from 'ramda'

let option: EChartsOption = {
  title: {
    show: false,
  },
  toolbox: false,
  legend: {
    right: 16,
    align: 'left',
    data: ['茶叶', '贡米', '稻蛙', '稻鱼', '销售额'],
    itemGap: 12,
    itemWidth: 10,
    itemHeight: 10,
    textStyle: {
      color: WHITE_ALPHA_60,
    },
  },
  xAxis: {
    type: 'category',
    axisLabel: {
      formatter: (value: string) => {
        return `${value}月`;
      },
    },
  },
  yAxis: [
    {
      type: 'value',
      name: '百万元',
      position: 'right',
      alignTicks: true,
      // max: 5,
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: WHITE_ALPHA_20,
        },
      },
    },
    {
      name: '千斤',
      type: 'value',
      // max: 5,
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: WHITE_ALPHA_20,
        },
      },
    }],
  textStyle: {
    fontFamily: DINGTALK_JINBUTI,
    fontStyle: 'italic',
    fontWeight: 400,
    color: WHITE_ALPHA_60,
  },
  dataZoom: [
    {
      start: 0,
      end: 30,
      borderColor: 'transparent',
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
  ],
  series: [
    {
      name: '销售额',
      type: 'line',
      symbol: 'circle',
      symbolSize: 6,
      color: GREEN_LINE,
      yAxisIndex: 0,
    },
    {
      name: '茶叶',
      type: 'bar',
      yAxisIndex: 1,
      itemStyle: {
        color: CHART_LIGHT_BLUE_LINEAR_GRADIENT,
      },
    },
    {
      name: '贡米',
      type: 'bar',
      yAxisIndex: 1,
      itemStyle: {
        color: CHART_LIGHT_GREEN_LINEAR_GRADIENT,
      },
    },
    {
      name: '稻蛙',
      type: 'bar',
      yAxisIndex: 1,
      itemStyle: {
        color: CHART_LIGHT_YELLOW_LINEAR_GRADIENT,
      },
    },
    {
      name: '稻鱼',
      type: 'bar',
      yAxisIndex: 1,
      itemStyle: {
        color: CHART_LIGHT_RED_LINEAR_GRADIENT,
      },
    },
  ],
}
export default function ProductMarketingOverview() {
  const [chartOption, setChartOption] = useState(option)
  const { data, loading } = useDataProvider<any[]>({
    api: '/marketinganalysis/smartMarketingAnalysis/get-line-chart',
    transform(data: DataResponse<ProductMarketing[]>) {
      return data.result?.map((item) => {
        const total = item?.product?.reduce((sum, cur) => sum + Number(cur?.salesAmount), 0) ?? [0, 0];
        const frog = item?.product?.find((item) => item?.productName === '稻蛙')?.salesVolume;
        const fish = item?.product?.find((item) => item?.productName === '稻鱼')?.salesVolume;
        const tea = item?.product?.find((item) => item?.productName === '茶叶')?.salesVolume;
        const rice = item?.product?.find((item) => item?.productName === '贡米')?.salesVolume;
        return {
          frog,
          fish,
          tea,
          rice,
          total: [item?.month, total],
        };
      })
    },
  })

  useEffect(() => {
    if (!data) return
    let opt = clone(option)
    opt.series[0].data = data?.map((item: any) => item.total)
    opt.series[1].data = data.map((item: any) => [item.total[0], item.tea])
    opt.series[2].data = data.map((item: any) => [item.total[0], item.rice])
    opt.series[3].data = data.map((item: any) => [item.total[0], item.frog])
    opt.series[4].data = data.map((item: any) => [item.total[0], item.fish])
    setChartOption(opt)
  }, [data])

  return (
    <div>
      <ReactEcharts
        // showLoading={loading}
        style={{
          width: 'calc(100% + 20px)',
          height: 260,
        }}
        option={chartOption}
        echarts={echarts} />
    </div>
  )
}
