import s from './ImageButton.module.css'
import classNames from 'classnames'

export interface ImageButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  name: string
  active?: boolean
  text?: string;
  icon?: JSX.Element;
}

export default function ImageButton(
  { name, active, text, icon, ...buttonProps }: ImageButtonProps,
) {
  return (
    <button className={classNames(
      s.imageButton,
      name,
      { [s.active]: active },
    )} {...buttonProps}>
      {icon}
      {text}
    </button>
  )
}
