import s from './InputSearch.module.css'

interface InputSearchProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function InputSearch({ onChange }: InputSearchProps) {
  return (
    <span className={s.inputSearch}>
      <input
        placeholder={`请输入追溯码查询...`}
        onChange={onChange}
      />
    </span>
  )
}
