import classNames from 'classnames';
import s from './SubTitleWithIcon.module.css';

interface SubTitleWithIconProps {
  title: string;
  dir?: 'left' | 'right';
  style?: React.CSSProperties;
}

export default function SubTitleWithIcon({ title, dir = 'left', style }: SubTitleWithIconProps) {
  return (
    <div className={classNames(s.subTitleWithIcon, { [s.reverse]: dir === 'right'})} style={style}>
      <div className={s.icon} />
      <h3 className={s.title}>{title}</h3>
      <div className={s.icon} />
    </div>
  )
}