import DetailContent, { ContentBlock } from '../DetailContent'
import detailMedia1 from '../../../assets/images/detail-content/detail-media-1.png'
import Divider from '../Divider'
import classNames from 'classnames'
import SvgIcon from '../svg-icon/SvgIcon'

export default function DetailContent1({ detail }: { detail?: PopularTravelling }) {
  return (
    <DetailContent content={
      <div>
        <span
          style={{ fontSize: 18 }}
          className={classNames(
            'teagarden-text-gradient',
            'green',
          )}
        >{detail?.scenicSpotName}</span>
        <p style={{
          fontSize: 12,
          fontWeight: 'normal',
        }}
          className={classNames(
            'teagarden-text-gradient',
            'green',
          )}
        >
          <SvgIcon name="local" style={{ marginRight: 4 }} />
          {detail?.address}
        </p>
        <Divider />
        <div style={{ display: 'flex', gap: 12, flexDirection: 'column' }}>
          <ContentBlock title={'购票须知'} icon={<SvgIcon name="purchaseNotes" />} content={detail?.ticketPurchaseNotice} />
          <ContentBlock title={'交通指南'} icon={<SvgIcon name="transportationGuide" />} content={detail?.transportationGuide} />
          <ContentBlock title={'景点介绍'} icon={<SvgIcon name="intro" />} content={detail?.scenicSpotIntroduction} />
          <ContentBlock title={'景区特色'} icon={<SvgIcon name="feature" />} content={detail?.scenicAreaFeatures} />
        </div>
      </div>
    } media={
      <img src={detailMedia1} alt="temple" />
    } />
  )
}
