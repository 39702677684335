import ReactEcharts from 'echarts-for-react'
import * as echarts from 'echarts'
import {
  CHART_GREEN_BG_LINEAR_GRADIENT,
  GREEN_LINE,
  WHITE_ALPHA_20,
  WHITE_ALPHA_60,
} from '../../constants/colors'
import { DINGTALK_JINBUTI } from '../../constants/font'
import useDataProvider from '../hooks/use-data-provider'
import {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { EChartsOption } from 'echarts-for-react/src/types'
import { clone } from 'ramda'
import Tab from './Tab'

let option: EChartsOption = {
  title: {
    show: false,
  },
  toolbox: false,
  xAxis: {
    type: 'time',
    axisLabel: {
      formatter: (value: any) => {
        const date = new Date(value)
        return date.getFullYear().toString()
      },
    },
  },
  yAxis: {
    name: '元',
    type: 'value',
    splitLine: {
      lineStyle: {
        type: 'dashed',
        color: WHITE_ALPHA_20,
      },
    },
  },
  textStyle: {
    fontFamily: DINGTALK_JINBUTI,
    fontStyle: 'italic',
    fontWeight: 400,
    color: WHITE_ALPHA_60,
  },
  dataZoom: [
    {
      start: 0,
      end: 80,
      borderColor: 'transparent',
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
  ],
  series: [
    {
      name: '销售额',
      type: 'line',
      symbol: 'circle',
      symbolSize: 6,
      color: GREEN_LINE,
      areaStyle: {
        color: CHART_GREEN_BG_LINEAR_GRADIENT,
      },
    },
  ],
}

export default function CommodityPriceTrend() {
  const [typeName, setType] = useState('贡米');

  const [chartOption, setChartOption] = useState(option)
  const { data, fetchData } = useDataProvider({
    api: `/product/product-price?name=${typeName}`,
    transform(data: DataResponse<PriceTrend[]>) {
      return data.result.map((item) => {
        return [new Date(item.name, 0, 1).getTime(), item.value];
      }).sort((item1, item2) => item1[0] - item2[0]);
    },
  })

  useEffect(() => {
    fetchData();
  }, [typeName]);

  useEffect(() => {
    let opt = clone(option)
    opt.series[0].data = data
    setChartOption(opt)
  }, [data]);

  const content = useMemo(() => (
    <div style={{ marginTop: -40 }}>
      <ReactEcharts
        style={{
          width: 'calc(100% + 24px)',
          height: 214,
        }}
        option={chartOption}
        echarts={echarts} />
    </div>
  ), [chartOption, echarts]);

  const items = useMemo(() => ['贡米', '贡茶', '稻蛙', '稻鱼'].map((name) => ({ name, content })), [content]);

  return (
    <Tab
      style={{ justifyContent: 'flex-end' }}
      items={items}
      onChange={setType}
    />
  )
}
