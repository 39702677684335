import { useMemo, useRef, useState } from 'react';
import TitleWithIcon from './components/TitleWithIcon';
import Intro from './components/Intro';
import BG from '../assets/images/agriculture-journey/bg.png';
import Layout from './components/Layout';
import HotTravelPlace from './components/HotTravelPlace';
import HotRestaurant from './components/HotRestaurant';
import VisitorTrafficOverview from './components/VisitorTrafficOverview';
import ProductMarketingOverview from './components/ProductMarketingOverview';
import TouristOverview from './components/TouristOverview';
import CheckboxList from './components/CheckboxList';
import Icon1 from '../assets/images/checkbox/restaurant-icon.svg';
import Icon2 from '../assets/images/checkbox/home-stay-icon.svg';
import Icon3 from '../assets/images/checkbox/place-icon.svg';
import Dropdown from './components/Dropdown';
import s from './AgricultureJourney.module.css';
import classNames from 'classnames';
import useWindowZoom from './hooks/use-window-zoom';
import useDataProvider from './hooks/use-data-provider';
import HistoryCultureModal from './components/modals/HistoryCultureModal';
import HistoryCulture from './components/HistoryCulture';

interface CulturButtonProps {
  active: "贡米" | "贡茶";
  text: string
}

export default function AgricultureJourney() {
  const zoom = useWindowZoom()

  const { data: tourismIntroduceItems } = useDataProvider({
    api: '/smarttourism/smartTourismIntroduce/get-all',
    transform: (d: DataResponse<TourismIntroduction[]>) => {
      return d.result.map((rec) => {
        return {
          title: rec.scenicSpotName,
          textContent: rec.introduction
        };
      })
    },
  });

  const routeData = useRef(["路线一", "路线二", "路线三", "路线四"]);
  const [active, setActive] = useState(0);

  const popularRoutes = useMemo(() => {
    return routeData.current.map((item, index) => {
      return (
        <div
          className={s.routeOption}
          key={`route-opt-${index}`}
          onClick={() => setActive(index)}
        >
          <span className={classNames(s.icon, { [s.active]: index === active })} />
          {item}
        </div>
      )
    })
  }, [active]);

  return (
    <Layout
      bg={BG}
      left={
        <>
          <div className="teagarden-layout-item">
            <TitleWithIcon title="美丽中峰" />
            <Intro title={tourismIntroduceItems?.[0]?.title} textContent={tourismIntroduceItems?.[0]?.textContent} />
          </div>
          <div className="teagarden-layout-item">
            <TitleWithIcon title="热门旅游" />
            <HotTravelPlace />
          </div>
          <div className="teagarden-layout-item">
            <TitleWithIcon title="热门餐饮民宿" />
            <HotRestaurant />
          </div>
        </>
      }
      right={
        <>
          <div className="teagarden-layout-item">
            <TitleWithIcon style={{ marginBottom: 0 }} title="景区客流分析" dir="right" />
            <VisitorTrafficOverview />
          </div>
          <div style={{ marginBottom: 18 }}>
            <TitleWithIcon title="旅游产品及营销分析" dir="right" />
            <ProductMarketingOverview />
          </div>
          <div className="teagarden-layout-item">
            <TitleWithIcon title="历史文化" dir="right" />
            <HistoryCulture />
          </div>
        </>
      }
    >
      <CheckboxList
        data={[
          { icon: Icon1, label: '餐饮' },
          { icon: Icon2, label: '民宿' },
          { icon: Icon3, label: '景点' },
        ]}
      />
      <div className="teagarden-button-group " style={{ zoom }}>
        <Dropdown
          label="热门路线"
          content={popularRoutes}
        />
      </div>
    </Layout>
  )
}