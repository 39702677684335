import classNames from 'classnames';
import GradientButton from './GradientButton';
import s from './HotPlate.module.css'
import ContentModal from './modals/ContentModal';
import DetailContent1 from './details/DetailContent1';
import DetailContent2 from './details/DetailContent2';
import DetailContent3 from './details/DetailContent3';

interface HotPlateProps {
  type: '景点'|'餐饮'|'民宿';
  width?: number;
  height: number;
  title: string;
  coverUrl: string;
  content: string[];
  pay?: string;
  detail?: PopularTravelling|HotRestaurant;
}

export default function HotPlate({ type, width, height, title, coverUrl, content, pay, detail }: HotPlateProps) {
  let detailContent = null;

  switch (type) {
    case '景点':
      detailContent = <DetailContent1 detail={detail as PopularTravelling} />;
      break;
    case '餐饮':
      detailContent = <DetailContent2 detail={detail as HotRestaurant} />;
      break;
    case '民宿':
      detailContent = <DetailContent3 detail={detail as HotRestaurant} />;
      break;
  }

  return (
    <div className={s.hotPlate} style={{ width, height }}>
      <div className={s.main}>
        <img src={coverUrl} className={s.cover} alt='' />
        <div className={s.content}>
          <h1 className={s.title}>{title}</h1>
          {
            content.map((item, index) => {
              return <p key={`hot-plate-${index}`}>{item}</p>
            })
          }
        </div>
      </div>
      <div className={s.actions}>
        {pay ?
          <GradientButton
            color="warning"
            style={{ padding: '5px 12px 5px 12px', marginBottom: '8px' }}
          >
            {pay}
          </GradientButton> : null
        }
        <div className={classNames('teagarden-text-gradient', s.detailButton)}>
          <ContentModal
            trigger={<span>详情</span>}
            content={detailContent}
          />
          <span className={s.arrowIcon} />
        </div>
      </div>
    </div>
  )
}