import { useCallback, useMemo, useState } from 'react';
import HotPlate from './HotPlate';
import Img from '../../assets/images/hot-plate/img-2.png';
import Tab from './Tab';
import useDataProvider from '../hooks/use-data-provider';
import classNames from 'classnames';
import s from './HotRestaurant.module.css'

export default function HotRestaurant() {
  const { data: hotRestaurantItems } = useDataProvider({
    api: '/catering/smartCateringAndAccommodation/get-all', // TODO: 测试 API 无返回数据
    transform: (d: DataResponse<HotRestaurant[]>) => {
      return d.result.map((rec) => {
        return {
          typeNumber: rec.classification, // 1-餐饮 2-民宿
          height: 68,
          title: rec.restaurantName,
          coverUrl: Img, //rec.picture, // TODO: 图片地址待处理
          content: [`${rec.featuredCuisine}`], // TODO: 不确定是否展示本字段
          detail: rec
        };
      })
    },
  });

  // 1-餐饮
  const restaurants = hotRestaurantItems?.filter(item => item.typeNumber == 1) || [
    {
      height: 68,
      title: "XXX餐厅",
      coverUrl: Img,
      content: ["汤清、味醇、色鲜"],
    },
    {
      height: 68,
      title: "乡趣·绿之恋旅游区",
      coverUrl: Img,
      content: ["最佳旅游时间：全年", "开放时间：全年"],
    },
    {
      height: 68,
      title: "九龙瀑景区",
      coverUrl: Img,
      content: ["最佳旅游时间：全年", "开放时间：08:30 - 17:00"],
    }
  ];
  // 2-民宿
  const homestay = hotRestaurantItems?.filter(item => item.typeNumber == 2) || [
    {
      height: 68,
      title: "XXX民宿",
      coverUrl: Img,
      content: ["汤清、味醇、色鲜"],
    },
    {
      height: 68,
      title: "乡趣·绿之恋旅游区",
      coverUrl: Img,
      content: ["最佳旅游时间：全年", "开放时间：全年"],
    },
    {
      height: 68,
      title: "九龙瀑景区",
      coverUrl: Img,
      content: ["最佳旅游时间：全年", "开放时间：08:30 - 17:00"],
    }
  ];

  const places = useMemo(() => [
    { name: '餐饮', content: restaurants },
    { name: '民宿', content: homestay }
  ], []);

  const list = useCallback((idx: number) => {
    const type = places[idx].name;
    return <div className={classNames('teagarden-overflow-scroll', s.hotRestaurant)}>
      {
        places[idx].content.map((c, idx) => {
          return (
            <HotPlate
              key={`hot-restaurant-${idx}`}
              type={type == '餐饮' ? '餐饮' : '民宿'}
              {...c}
            />
          )
        })
      }
    </div>
  }, []);

  const items = useMemo(() => {
    return places.map((p, idx) => {
      return {
        name: p.name,
        content: list(idx)
      }
    })
  }, [places]);

  return (
    <div>
      <Tab items={items} />
    </div>
  )
}