import LocalIcon from '../../../assets/images/svg-icon/local-icon.svg';
import FeatureIcon from '../../../assets/images/svg-icon/feature-icon.svg';
import IntroIcon from '../../../assets/images/svg-icon/intro-icon.svg';
import MoneyIcon from '../../../assets/images/svg-icon/money-icon.svg';
import PurchaseNotesIcon from '../../../assets/images/svg-icon/purchase-notes-icon.svg';
import TransportationGuideIcon from '../../../assets/images/svg-icon/transportation-guide-icon.svg';
import GongChaIcon from '../../../assets/images/modals/gongcha-icon.svg';
import GongMiIcon from '../../../assets/images/modals/gongmi-icon.svg';
import PlayIcon from '../../../assets/images/common/play-one.svg';
import FullscreenIcon from '../../../assets/images/common/full-screen.svg';

export type IconName = 'local' | 'feature' | 'intro' | 'price' | 'purchaseNotes' | 'transportationGuide' | 'gongcha' | 'gongmi' | 'play' | 'fullscreen';

const icons: Record<IconName, string> = {
  local: LocalIcon,
  feature: FeatureIcon,
  intro: IntroIcon,
  price: MoneyIcon,
  purchaseNotes: PurchaseNotesIcon,
  transportationGuide: TransportationGuideIcon,
  gongcha: GongChaIcon,
  gongmi: GongMiIcon,
  play: PlayIcon,
  fullscreen: FullscreenIcon,
}

export default icons;