import s from './DetailContent.module.css'
import { ReactNode } from 'react'
import classNames from 'classnames'

export interface DetailContentProps {
  content: ReactNode
  media?: ReactNode
}

export default function DetailContent(
  { content, media }: DetailContentProps,
) {
  return (
    <div className={s.detailContent}>
      <div style={{height:'100%'}}>{content}</div>
      <div>{media}</div>
    </div>
  )
}

export interface ContentBlockProps {
  title?: string
  content: ReactNode
  icon?: ReactNode
}

export function ContentBlock(
  { title, content, icon }: ContentBlockProps,
) {
  return (
    <div className={s.contentBlock}>
      <div>
        {icon}
      </div>
      <div>
        <div className={classNames(
          s.contentBlockTitle,
          'teagarden-text-gradient',
          'green',
        )}>{title}</div>
        <div className={s.contentBlockContent}>
          {content}
        </div>
      </div>
    </div>
  )
}
