import { useCallback, useState } from 'react';
import classNames from 'classnames';
import GradientText from './GradientText';
import s from './CheckboxList.module.css';
import useWindowZoom from '../hooks/use-window-zoom';

interface CheckboxProps {
  icon: string;
  label: string;
  isChecked?: boolean;
}

export const Checkbox = ({ icon, label, isChecked }: CheckboxProps) => {
  const [checked, setChecked] = useState(isChecked);

  return (
    <div
      className={classNames("teagarden-pointer-item", s.checkboxWrapper)}
      onClick={() => { setChecked(!checked) }}
    >
      <div className={s.label}>
        <span className={s.icon} style={{ backgroundImage: `url(${icon})` }} />
        <GradientText>{label}</GradientText>
      </div>
      <div className={s.checkbox}>
        {checked && <span className={s.checkmark} />}
      </div>
    </div>
  )
}

interface CheckboxListProps {
  data: { icon: string, label: string }[];
}

export default function CheckboxList({ data }: CheckboxListProps) {
  const zoom = useWindowZoom()
  return (
    <div className={s.checkboxListWrapper} style={{ zoom }}>
      <div className={s.checkboxList}>
        {
          data.map((item, idx) => {
            return (
              <div key={`check-item-${idx}`}>
                <Checkbox icon={item.icon} label={item.label} />
              </div>
            )
          })
        }
      </div>
      <div className={classNames(s.border, s.topBorder)} />
      <div className={classNames(s.border, s.bottomBorder)} />
    </div>
  )
}