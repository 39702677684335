import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import NavIcon1 from '../../assets/images/guide/nav-icon-1.svg'
import NavIcon2 from '../../assets/images/guide/nav-icon-2.svg'
import NavIcon3 from '../../assets/images/guide/nav-icon-3.svg'
import { useLocation } from 'react-use'

export default function useNavDataProvider() {
  const navigate = useNavigate()
  const location = useLocation()

  const items = useMemo(() => {
    return [
      {
        src: NavIcon1,
        label: '智慧农业',
        active: location.pathname === '/intelligent-agriculture',
        onClick: () => {
          navigate('/intelligent-agriculture')
        },
      },
      {
        src: NavIcon2,
        label: '智慧农旅',
        active: location.pathname === '/agriculture-journey',
        onClick: () => {
          navigate('/agriculture-journey')
        },
      },
      {
        src: NavIcon3,
        label: '生产溯源',
        active: location.pathname === '/traceability',
        onClick: () => {
          navigate('/traceability')
        },
      },
    ]
  }, [])

  return items
}
