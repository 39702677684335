import s from './AgriculturalProductTraceability.module.css'
import InputSearch from './InputSearch'
import logo from '../../assets/images/common/search-img-placeholder.png'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Tab from './Tab';
import useDataProvider from '../hooks/use-data-provider';
import qs from 'qs';

export interface AgriculturalProductTraceabilityProps {

}

export default function AgriculturalProductTraceability() {
  const [type, setType] = useState(1);
  const [traceSourceCode, setTraceSourceCode] = useState<string | undefined>(undefined);

  const { data, fetchData } = useDataProvider({
    api: `/traceable/list?${qs.stringify({ type, traceSourceCode })}`,
    transform: (d: DataResponse<ProductTraceabilityData>) => {
      return d.result?.records?.[0];
    },
  });

  useEffect(() => {
    fetchData();
  }, [type, traceSourceCode]);

  const tabItemData = useMemo(() => [
    { name: '贡米' },
    { name: '茶叶' },
  ], []);

  const caculateArea = useCallback((area: number) => {
    if (area > 10000) {
      return `${(area / 10000).toFixed(2)}万方米`
    }
    return `${area}方米`;
  }, []);

  const maskPhoneNumber = useCallback((phoneNumber: string) => {
    if (!phoneNumber) return;

    const prefix = phoneNumber.substring(0, 3);
    const suffix = phoneNumber.substring(7);

    return `${prefix}****${suffix}`;
  }, []);

  const content = useMemo(() => {
    return (
      <div style={{marginTop: -8}}>
        <InputSearch onChange={(event) => {setTraceSourceCode(event.target.value)}} />
        <div className={s.searchInfo}>
          <div className={s.img}>
            <img className={s.searchInfoImg} src={logo} width={80} height={80} />
          </div>
          <div className={s.detail}>
            <div><label>企业名称：</label><span>{data?.enterpriseName}</span></div>
            <div><label>产业园面积：</label><span>{caculateArea(data?.areaOrigin || 0)}</span></div>
            <div><label>产业地址：</label><span>{data?.address}</span></div>
            <div><label>负责人：</label><span>{data?.name} | {maskPhoneNumber(data?.phone || '')}</span></div>
          </div>
        </div>
      </div>
    )
  }, [data]);

  const items = useMemo(() => tabItemData?.map((item, idx) => {
    return {
      ...item,
      content: (
        <div className={s.content} key={`traceability-item-${idx}`}>
          {content}
        </div>
      )
    }
  }), [content]);
  return (
    <div className={s.agriculturalProductTraceability}>
      <Tab
        items={items}
        style={{ justifyContent: 'flex-end' }}
        onChange={(name: string) => {
          const t = name == '贡米' ? 1 : 2
          setType(t)
        }}
      />
    </div>
  )
}
